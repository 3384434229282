// THIS UTILITY ONLY EXISTS TO RECOGNIZE WICH ROUTES ARE EXTERNAL AND WHERE THEY ARE PLACED
// import { reactiveApiUrls } from '@core/utils/api'
import { cluberLegacy } from '@core/config'
import { useCluberLocalStorage } from '@cluber/tools-composables'
import { type LocalStorageAuth } from '@core/modules/Auth/declarations'

type StorageWithToken = Omit<LocalStorageAuth, 'currentProfile'> & { xAuthTokenApplied: boolean }

export function navigateToLegacy(path: string, query?: Record<string, unknown>) {
  const { storage } = useCluberLocalStorage<StorageWithToken>({
    auth: { token: '', userIdentifier: '' },
    xAuthTokenApplied: false,
  }, { mergeDefaults: true })

  const queryWithToken = { ...query }
  if (storage.value?.auth.token) {
    const { xAuthTokenApplied } = storage.value
    if (!xAuthTokenApplied) {
      Object.assign(queryWithToken, { 'X-AUTH-TOKEN': storage.value.auth.token })
      storage.value.xAuthTokenApplied = true
    }
  }

  const urlToNavigate = new URL(`${cluberLegacy.baseUrl}${path}`)
  if (Object.keys(queryWithToken).length) {
    // @ts-expect-error Unknown solution
    urlToNavigate.search = Object.entries(queryWithToken).map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`).join('&')
  }

  window.location.href = urlToNavigate.toString()
}

export function navigateToCluber(path: string, query?: Record<string, unknown>) {
  const cluberBaseUrl = 'https://cluber.es'
  const urlToNavigate = new URL(`${cluberBaseUrl}${path}`)
  if (query) {
    // @ts-expect-error Unknown solution
    urlToNavigate.search = Object.entries(query).map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`).join('&')
  }
  window.location.href = urlToNavigate.toString()
}
