import { http, HttpResponse } from 'msw'
import { getClubByHash } from '@core/modules/Club/api'
import { getApiRoute } from '@core/utils/api'

export const getClubByHashHandler = http.get(
  getApiRoute<{ clubHash: string }>(getClubByHash.url, { params: { clubHash: '*' } }),
  ({ request, params, cookies }) => HttpResponse.json({
    '@context': '/api/contexts/Club',
    '@id': '/api/clubs/3abf15a877a6dabfc67806017ab195526a3b011d',
    '@type': 'Club',
    hash: '3abf15a877a6dabfc67806017ab195526a3b011d',
    name: 'Club Deportivo Arsenaloooooo',
  }),
)
