import { EndpointConfig } from '@core/store/apiRequest/declarations'

export const getMailboxesProfiles : EndpointConfig = {
  storeKey: 'getMailboxesProfiles',
  url: '/api/clubs/:clubHash/communications/mailboxes/:mailboxHash/profiles',
}

export const getMailbox: EndpointConfig = {
  storeKey: 'getMailbox',
  url: '/api/clubs/:clubHash/communications/mailboxes/:hash',
}

export const getThreadDetails: EndpointConfig = {
  storeKey: 'getThreadDetails',
  url: '/api/clubs/:clubHash/communications/threads/:threadHash',
}

export const getMessageThreads: EndpointConfig = {
  storeKey: 'getMessageThreads',
  url: '/api/clubs/:clubHash/communications/threads',
}

export const deleteMessageThread: EndpointConfig = {
  storeKey: 'deleteMessageThread',
  url: '/api/clubs/:clubHash/communications/threads/:threadHash',
}

export const postCreateThread: EndpointConfig = {
  storeKey: 'postCreateThread',
  url: '/api/clubs/:clubHash/communications/threads',
}

export const postCreateMessageInThread: EndpointConfig = {
  storeKey: 'postCreateMessageInThread',
  url: '/api/clubs/:clubHash/communications/threads/:threadHash',
}

export const getAccesibleParticipants: EndpointConfig = { // Send as
  storeKey: 'getAccesibleParticipants',
  url: '/api/clubs/:clubHash/communications/accesible-participants',
}

export const getAllowedRecipients: EndpointConfig = { // Send to
  storeKey: 'getAllowedRecipients',
  url: '/api/clubs/:clubHash/communications/allowed-recipients',

}

export const getThreadConversations: EndpointConfig = {
  storeKey: 'getThreadConversations',
  url: '/api/clubs/:clubHash/communications/threads/:threadHash/conversations',
}

export const getThreadSingleConversation: EndpointConfig = {
  storeKey: 'getThreadSingleConversation',
  url: '/api/clubs/:clubHash/communications/threads/:threadHash/conversations/:conversationHash',
}

export const getThreadBroadcastMessages: EndpointConfig = {
  storeKey: 'getThreadBroadcastMessages',
  url: '/api/clubs/:clubHash/communications/threads/:threadHash/broadcast-messages',
}

export const getThreadParticipants: EndpointConfig = {
  storeKey: 'getThreadParticipants',
  url: '/api/clubs/:clubHash/communications/threads/:threadHash/recipients',
}

export const postClubContactMessage: EndpointConfig = {
  storeKey: 'postClubContactMessage',
  url: '/api/clubs/:clubHash/profile-contact-club',
}

export const getUnreadMessages: EndpointConfig = {
  storeKey: 'getUnreadMessages',
  url: '/api/clubs/:clubHash/communications/unread',
}
