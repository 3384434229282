import { http, HttpResponse } from 'msw'
import { postUserToken, getUserProfile } from '@core/modules/Auth/api'
import { getApiRoute } from '@core/utils/api'

import user from '../../mocks/msw/auth.json'
import profile from '../../mocks/msw/profile.json'

export const postTokenHandler = http.post(
  getApiRoute(postUserToken.url),
  ({ request, params, cookies }) => HttpResponse.json(user),
)

export const getUserProfileHandler = http.get(
  getApiRoute(getUserProfile.url),
  ({ request, params, cookies }) => HttpResponse.json(profile),
)
