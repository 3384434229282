import { type RouteRecordRaw } from 'vue-router'

import { type ModuleRoute } from '@core/declarations/router'
import { UserKind } from '@libs/fsc/types/user'

const Agenda = () => import('@dashboard/modules/Bookings/views/Agenda.vue')
const Calendar = () => import('@dashboard/modules/Bookings/views/Calendar.vue')
const Availability = () => import('@dashboard/modules/Bookings/views/Availability.vue')
const PricingPolicies = () => import('@dashboard/modules/Bookings/views/PricingPolicies.vue')
const LinkGroups = () => import('@dashboard/modules/Bookings/views/LinkGroups.vue')

function generateBookingSharedRoutes(role: UserKind.Manager) : RouteRecordRaw[] {
  const routeAgendaByRole = {
    [UserKind.Manager]: 'managerBookingAgenda',
  }

  const routeCalendarByRole = {
    [UserKind.Manager]: 'managerBookingCalendars',
  }

  const routeAvailabilityByRole = {
    [UserKind.Manager]: 'managerBookingAvailability',
  }

  return [
    {
      path: ':clubHash/agenda',
      name: routeAgendaByRole[role],
      component: Agenda,
      meta: {
        tabTitle: 'Agenda',
      },
    },
    {
      path: ':clubHash/calendars',
      name: routeCalendarByRole[role],
      component: Calendar,
      meta: {
        tabTitle: 'Booking Calendars',
      },
    },
    {
      path: ':clubHash/availability',
      name: routeAvailabilityByRole[role],
      component: Availability,
      meta: {
        tabTitle: 'Availability',
      },
    },
  ]
}

export const managerBookingRoutes = [
  {
    path: ':clubHash/pricing-policies',
    name: 'managerBookingPricingPolicies',
    component: PricingPolicies,
    meta: {
      tabTitle: 'Pricing Policies',
    },
  },
  {
    path: ':clubHash/link-groups',
    name: 'managerBookingLinkGroups',
    component: LinkGroups,
    meta: {
      tabTitle: 'Link Groups',
    },
  },
]

function generateBookingRoutes(role: UserKind.Manager) : ModuleRoute[] {
  const bookingRoutesByRole = {
    [UserKind.Guardian]: 'GuardianBooking',
    [UserKind.Member]: 'MemberBooking',
    [UserKind.Manager]: 'ManagerBooking',
  }

  const exclusiveBookingRoutes = {
    [UserKind.Manager]: managerBookingRoutes,
  }

  return [{
    name: bookingRoutesByRole[role],
    path: '/bookings',
    children: [
      ...generateBookingSharedRoutes(role),
      ...exclusiveBookingRoutes[role],
    ],
  }]
}

export const managerBooking = generateBookingRoutes(UserKind.Manager)
