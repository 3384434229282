import { ModuleRoutes } from '@core/declarations/router'

const ProfileSelector = () => import('@dashboard/modules/ProfileSelector/views/ProfileSelector/ProfileSelector.vue')

const routes: ModuleRoutes = {
  name: 'ProfileSelector',
  basePath: '/dashboard/',
  routes: [
    {
      path: 'profile-selector',
      name: 'profileSelector',
      component: ProfileSelector,
      meta: {
        tabTitle: 'Profile selector',
        navigationType: 'clean',
      },
    },
  ],
}

export default routes
