<script setup lang="ts">
import { ref } from 'vue'
import { CbSwitch } from '@cluber/carabiner'
import { useAppConfigStore } from '@core/store/app'

import Endpoints from './Endpoints.vue'

// ***
// DEFAULTS
// ***
const appConfig = useAppConfigStore()

// ***
// STAGING BANNER
// ***
const isStagingBannerHidden = ref(appConfig.$state.isStagingBannerHidden)

function toggleStagingBanner() {
  appConfig.$patch({
    isStagingBannerHidden: !isStagingBannerHidden.value,
  })
  isStagingBannerHidden.value = !isStagingBannerHidden.value
}

// ***
// LANGUAGE
// ***

</script>

<template>
  <h2 class="underline font-bold">
    Staging banner
  </h2>
  <CbSwitch
    v-model="isStagingBannerHidden"
    class="mt-5"
    name="switch-input"
    @input="toggleStagingBanner"
  >
    Banner <strong>{{ isStagingBannerHidden ? 'IS HIDDEN' : 'IS VISIBLE' }}</strong>
  </CbSwitch>
  <p class="mt-5 p-5 bg-base-200 max-w-96 rounded text-sm">
    This parameter only works during your current session, meaning that this setting will <strong>be reset when refreshing, closing your current tab or opening a new one.</strong>
  </p>
  <hr class="my-5">
  <Endpoints />
</template>
