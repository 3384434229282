<script setup lang="ts">
import { useI18n } from 'vue-i18n'

import ClubContactForm from '../ClubContactForm/ClubContactForm.vue'

const { t } = useI18n()
const emit = defineEmits(['success', 'error'])

</script>

<template>
  <div>
    <h3 class="font-display font-semibold text-3xl mb-8">
      {{ t('Club Information') }}
    </h3>
    <ClubContactForm
      subject="club-info"
      @success="() => emit('success')"
      @error="() => emit('error')"
    />
  </div>
</template>
