import { navigateToLegacy } from '@libs/fsc/utils/navigateToLegacy'

import { NavigationItem } from '../declarations'
import { getParam } from './utils'

export { default as navigationStructureManager } from './manager'
export { default as navigationStructureGuardian } from './guardian'
export { default as navigationStructureMember } from './member'

export const lowerNavigation: NavigationItem[] = [
  {
    id: 'administration',
    label: 'Administration',
    submenu: [
      {
        id: 'admin-plans',
        label: 'Plans',
        action: ({ router }) => {
          if (router) {
            navigateToLegacy(`/clubes/${getParam(router, 'clubHash')}/facturacion`)
          }
        },
      },
      {
        id: 'admin-settings',
        label: 'Settings',
        action: ({ router }) => {
          if (router) {
            navigateToLegacy(`/clubes/${getParam(router, 'clubHash')}/ajustes`)
          }
        },
      },
      {
        id: 'admin-fee',
        label: 'Fees',
        action: ({ router }) => {
          if (router) {
            navigateToLegacy(`/clubes/${getParam(router, 'clubHash')}/cargos`)
          }
        },
      },
    ],
  },
  {
    id: 'support',
    label: 'Support',
    action: () => {},
  },
]
