<script setup lang="ts">
import { computed, type PropType } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'

import { CbModalStatus } from '@cluber/carabiner'

import { RoleKind } from '@libs/fsc/types/user'

// ***
// DEFAULTS
// ***

defineProps({
  status: {
    type: String as PropType<'success' | 'error' | 'warning'>,
    required: true,
  },
})

const emit = defineEmits(['close'])

const { t } = useI18n()
const route = useRoute()
const router = useRouter()

// ***
// REDIRECT TO MESSAGES
// ***

const messagesByRole = {
  [RoleKind.Member]: 'memberMyMessagesList',
  [RoleKind.Guardian]: 'guardianMyMessagesList',
  [RoleKind.Manager]: 'managerMyMessagesList',
}

const messagesRoute = computed(() => messagesByRole[route.meta.roleKind as keyof typeof messagesByRole])

function redirectToMessages() {
  // Close modal on redirect
  emit('close')
  router.push({ name: messagesRoute.value, params: { clubHash: route.params.clubHash } })
}

</script>

<template>
  <CbModalStatus
    :status="status"
  >
    <template #title>
      {{ status === 'success' ? t('Information has been sent') : t('Something went wrong') }}
    </template>
    <template #description>
      <template v-if="status === 'success'">
        <p class="text-lg text-start">
          {{ t('We have already forwarded your inquiry/request to the club for processing as soon as possible.') }}
        </p>
        <i18n-t
          class="mx-auto mt-3 text-lg text-start"
          keypath="In any case, remember that you can always contact the club directly through the {messages} option in the side menu."
          tag="p"
        >
          <template #messages>
            <a
              class="font-bold text-primary-500 hover:underline cursor-pointer"
              @click="redirectToMessages"
              @keypress.enter="redirectToMessages"
            >
              {{ t('Messages') }}
            </a>
          </template>
        </i18n-t>
      </template>
      <template v-if="status === 'error'">
        {{ t('An error happened and we are not able to send your message') }}
      </template>
    </template>
  </CbModalStatus>
</template>
