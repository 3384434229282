<script setup lang="ts">
import { type PropType, computed } from 'vue'
import { useRouter } from 'vue-router'

import { useGetLegacyPeopleGroupByClub } from '@dashboard/modules/ProfileSelector/composables'

import { type NavItemRoute, type NavigationItem } from '../declarations'

import NavItemBadge from '../NavItemBadge/NavItemBadge.vue'

// ***
// DEFAULTS
// ***
const router = useRouter()

const {
  currentUser,
} = useGetLegacyPeopleGroupByClub()

const emit = defineEmits([
  'nav-item-clicked',
])

const props = defineProps({
  id: {
    type: String,
    required: true,
  },
  route: {
    type: Object as PropType<NavItemRoute>,
    default: undefined,
  },
  action: {
    type: Function as PropType<NavigationItem['action']>,
    default: undefined,
  },
  icon: {
    type: String,
    default: undefined,
  },
  badge: {
    type: String,
    default: undefined,
  },
})

// ***
// NAVIGATION
// ***
const isExternalLinkOrAction = computed(() => props.action || props.route?.path?.startsWith('http'))

function doNavigation() {
  router.push(props.route?.path ? { path: props.route.path } : { name: props.route?.name })
}

// ***
// ACTIONS
// ***

function runAction(action: NavigationItem['action']) {
  emit('nav-item-clicked', props.id)
  if (action) {
    action({ router, currentUser: currentUser.value })
  }
}
</script>

<template>
  <li
    class="flex items-center w-full p-3 hover:bg-base-300 dark:hover:bg-base-500 cursor-pointer"
    tabindex="0"
    @click="action ? runAction(action) : doNavigation()"
    @keydown.enter="action ? runAction(action) : doNavigation()"
  >
    <span
      v-if="icon"
      :class="`bi ${icon} mr-3`"
      data-testid="navitem-prefix-icon"
    />
    <template v-if="isExternalLinkOrAction">
      <p
        v-if="action"
      >
        <slot />
      </p>
      <a
        v-else
        :href="route?.path"
        rel="noopener noreferrer"
      >
        <slot />
      </a>
    </template>
    <span v-else>
      <slot />
    </span>
    <NavItemBadge
      :badge="badge"
    />
  </li>
</template>
