<script setup lang="ts">
import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute, useRouter } from 'vue-router'
import {
  CbBadge,
  CbDropdown,
  CbAvatar,
  CbModal,
} from '@cluber/carabiner'
import { useGetLegacyPeopleGroupByClub } from '@dashboard/modules/ProfileSelector/composables'

import { type ExcludeExternalRole } from '@libs/fsc/types/user'
import { badgeByRole, translationByRole } from '@libs/fsc/utils/roles'

import ChangePassword from './Modal/ChangePassword/ChangePassword.vue'

// ***
// DEFAULT
// ***
const { t } = useI18n()
const route = useRoute()
const router = useRouter()
const { currentUser } = useGetLegacyPeopleGroupByClub()

const roleKind = computed(() => route.meta.roleKind as ExcludeExternalRole)

// ***
// MODAL
// ***
const isModalOpen = ref(false)
const modalKind = ref()

function showChangePasswordModal() {
  isModalOpen.value = true
  modalKind.value = 'password'
}

// ***
// NAVIGATION
// ***
function navigateTo(routeName: string | { name: string }) {
  router.push(routeName)
}

</script>

<template>
  <div class="flex flex-col items-center my-8">
    <CbAvatar
      :loading="!currentUser?.clubLogo"
      :image="currentUser?.clubLogo || ''"
      size="big"
      class="mb-3"
    />
    <div class="flex flex-col items-center mb-2">
      <h3
        class="w-11/12 text-center truncate hover:whitespace-break-spaces"
      >
        {{ currentUser?.clubName }}
      </h3>
      <CbBadge
        class="mt-3 uppercase"
        :mode="badgeByRole(roleKind)"
      >
        {{ t(translationByRole(roleKind)) }}
      </CbBadge>
    </div>
    <CbDropdown
      class="w-full m-auto mt-4 text-center"
      close-on-scroll
      close-on-outside
    >
      <template #button="{ isOpen }">
        <p class="pt-4 cursor-pointer">
          {{ currentUser?.name }} {{ currentUser?.lastname }}
          <span
            :class="[
              'bi text-xs ml-3',
              isOpen ? 'bi-caret-up-fill' : 'bi-caret-down-fill',
            ]"
          />
        </p>
      </template>
      <template #menu>
        <ul
          class="mt-3 bg-white shadow dark:bg-base-700"
          :style="{
            width: 'var(--cluber-app-nav-width)',
          }"
        >
          <li
            @click="() => navigateTo('/')"
            @keydown.enter="() => navigateTo('/')"
          >
            {{ t('My profiles') }}
          </li>
          <li
            @click="showChangePasswordModal"
            @keydown.enter="showChangePasswordModal"
          >
            {{ t('Change password') }}
          </li>
          <li
            class="text-danger-500 dark:bg-white"
            @click="() => navigateTo({ name: 'authLogout' })"
            @keydown.enter="() => navigateTo({ name: 'authLogout' })"
          >
            {{ t('Sign out') }}
          </li>
        </ul>
      </template>
    </CbDropdown>
    <CbModal
      v-if="modalKind === 'password'"
      v-model="isModalOpen"
      content-classes="max-w-[36rem]"
    >
      <template #content>
        <ChangePassword />
      </template>
    </CbModal>
  </div>
</template>

<style scoped lang="postcss">
ul li {
  @apply p-5;

  &:hover {
    @apply bg-base-200 dark:bg-base-500 cursor-pointer;
  }
}
</style>
