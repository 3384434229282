import { ref, computed } from 'vue'
import { useCluberLocalStorage } from '@cluber/tools-composables'
import { useRouter } from 'vue-router'

import { useApiStore, isJWTExpired, axiosInstance } from '@core/store/apiRequest/apiRequest'
import { ApiAuthStoreShape } from '@core/modules/Auth/declarations'
import { type LocalStorageAuth } from '@core/modules/Auth/declarations'

export const useApiAuthStore = useApiStore<'apiAuth', ApiAuthStoreShape>('apiAuth')

const { storage } = useCluberLocalStorage<Omit<LocalStorageAuth, 'currentProfile'>>({
  auth: { token: '', userIdentifier: '' },
}, { mergeDefaults: true })

export function manageUserAuth() {
  const apiAuthStore = useApiAuthStore()
  const isAuthenticated = ref(false)
  const currentUserToken = computed(() => apiAuthStore.$state.postAuthToken?.token || storage.value.auth.token)

  async function initializeUserAuth() {
    if (currentUserToken.value) {
      apiAuthStore.$state.postAuthToken = { token: currentUserToken.value }
      axiosInstance.defaults.headers.common.Authorization = `Bearer ${currentUserToken.value}`
      if (!isJWTExpired(currentUserToken.value)) {
        isAuthenticated.value = isJWTExpired(currentUserToken.value)
      }
    }
  }

  return {
    isAuthenticated,
    initializeUserAuth,
    currentUserToken,
  }
}
