/* eslint-disable import/extensions */
import { createApp } from 'vue'
import { plugin, defaultConfig } from '@formkit/vue'
import { useEncryption } from '@cluber/tools-composables'

// @ts-expect-error export from cluber lib includes extension
import formkitConfig from '@cluber/carabiner/dist/formkit.config.ts'

import { type RouteLocation } from 'vue-router'
import store from '@core/store'
import router from '@core/router'
import { userAccessValidation } from '@core/modules/Auth/routesGuards'
import { loadAppModules } from '@core/modules/loadModules'
import { handlers } from '@core/mocks/handlers'
import i18n from '@core/locales/i18n'
import { allowMSW } from '@core/config'

import App from './App.vue'
import '@cluber/carabiner/dist/carabiner.css'
import '@core/assets/css/main.css'

// SET ENCRYPTION

const { setEncryptionConfig } = useEncryption()

setEncryptionConfig({
  initiVector: import.meta.env.VITE_ENCRYPT_INITIALIZATION_VECTOR,
  algorithm: import.meta.env.VITE_ENCRYPT_ALGORITHM,
})

// REGISTER APP MODULES
loadAppModules()

// REGISTER APP
const app = createApp(App)

// PLUGINS
app.use(store)
app.use(router)
app.use(i18n)
app.use(plugin, defaultConfig(formkitConfig))

// Load Mocking service worker in browser mode
if (import.meta.env.MODE !== 'production' && allowMSW) {
  await import('@libs/mockRest/browser').then(msw => msw.loadBrowserMSW(handlers))
}

//* Router
router.beforeEach((to: RouteLocation, from: RouteLocation) => userAccessValidation(to, from))

// MOUNT APP (when router isReady)
app.mount('#app')
// await router.isReady().then(() => app.mount('#app')) // DON'T REMOVE THIS COMMENT UNTIL PROD DEPLOY
