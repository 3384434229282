import { type ClubGroupLegacy } from '@libs/fsc/types/legacy'

export const groupByClubLegacy: ClubGroupLegacy = {
  club: {
    name: 'Lugo Sala',
    shortname: 'lugo_sala',
    companyName: 'Lugo Sala',
    image: 'https://s3-eu-west-1.amazonaws.com/stl-clubs/logos/911.jpeg',
    id: 'f3594450c5b729898f1b0de6792a96215cb8ace5',
    enabledFeatures: [
      { code: 'area_privada_socios' },
      { code: 'purchable_items' },
    ],
    privacy: {
      url: 'https://dev.siguetuliga.com/es/politica-privacidad/notfound',
      basicInfo: 'Informaci\u00f3n B\u00e1sica sobre Protecci\u00f3n de Datos<br> <b>Responsable:</b> Lugo Sala<br> <b>Finalidad:</b> Gestionar su registro como integrante de la entidad deportiva<br> <b>Procedencia de los datos:</b> El interesado o su tutor legal<br> <b>Derechos:</b> Acceder, rectificar y suprimir los datos, as\u00ed como otros derechos, como se explica en la informaci\u00f3n adicional que puede consultar en la pol\u00edtica de privacidad de la web de la entidad',
      custom: true,
      version: 142,
      customUrl: false,
    },
  },
  persons: [{
    type: 'manager',
    email: null,
    club: {
      name: 'Lugo Sala',
      shortname: 'lugo_sala',
      companyName: 'Lugo Sala',
      image: 'https://s3-eu-west-1.amazonaws.com/stl-clubs/logos/911.jpeg',
      id: 'f3594450c5b729898f1b0de6792a96215cb8ace5',
      enabledFeatures: [
        { code: 'area_privada_socios' },
        { code: 'purchable_items' },
      ],
      privacy: {
        url: 'https://dev.siguetuliga.com/es/politica-privacidad/notfound',
        basicInfo: 'Informaci\u00f3n B\u00e1sica sobre Protecci\u00f3n de Datos<br> <b>Responsable:</b> Lugo Sala<br> <b>Finalidad:</b> Gestionar su registro como integrante de la entidad deportiva<br> <b>Procedencia de los datos:</b> El interesado o su tutor legal<br> <b>Derechos:</b> Acceder, rectificar y suprimir los datos, as\u00ed como otros derechos, como se explica en la informaci\u00f3n adicional que puede consultar en la pol\u00edtica de privacidad de la web de la entidad',
        custom: true,
        version: 142,
        customUrl: false,
      },
    },
    roles: ['m_admin'],
    appliedClub: null,
    name: 'Siguetuliga',
    surname: 'Clubs',
    dni: null,
    enabled: true,
    terms: {
      custom: false,
      version: 2,
    },
    phone: 'siguetuliga',
    applicant: false,
    qrIdentifier: 'mg-f3594450c5b729898f1b0de6792a96215cb8ace5-d70c46bb345f90da404c4e523442d5e9e1c102a5',
    id: 'd70c46bb345f90da404c4e523442d5e9e1c102a5',
    subsidies: [],
  }],
}
