import { EndpointConfig } from '@core/store/apiRequest/declarations'

export const getClubByHash: EndpointConfig = {
  storeKey: 'getClubByHash',
  url: '/api/clubs/:clubHash',
}

export const postClubPublicFiles: EndpointConfig = {
  storeKey: 'postClubPublicFiles',
  url: '/api/clubs/:clubHash/files/public',
}

export const getClubEnabledFeatures: EndpointConfig = {
  storeKey: 'getClubEnabledFeatures',
  url: '/api/clubs/:clubHash/features',
}
