import { StoreDefinition } from 'pinia'
import { type Router } from 'vue-router'

import { ModuleRoutes } from '@core/declarations/router'

export class AppModule {
  constructor(
    private name: string = '',
    private routes?: ModuleRoutes,
    private store?: StoreDefinition,
  ) {}

  load(router: Router) {
    if (this.routes) {
      this.routes.routes.forEach((route) => {
        const basePath = this.routes?.basePath || ''
        router.addRoute({
          name: route.name,
          path: `${basePath}${route.path}`,
          component: route.component,
          redirect: route.redirect,
          meta: {
            isPublic: route.isPublic || this.routes?.isPublic,
            tabTitle: route.meta?.tabTitle,
            navigationType: route.meta?.navigationType || 'dashboard',
            roleKind: this.routes?.roleKind || route.meta?.roleKind,
            ...route.meta,
          },
          children: route?.children || [],
        })
      })
    }
  }

  getStore() {
    if (!this.store) {
      // eslint-disable-next-line no-console
      console.error(`No store defined for module: '${this.name}'`)
      return {}
    }

    return this.store()
  }
}
