import { ApiEcommerceProducts, ApiEcommerceCampaignOrders } from '@dashboard/modules/Ecommerce/declarations'

export const campaignOrders: ApiEcommerceCampaignOrders[] = [
  {
    '@context': 'string',
    '@id': 'string',
    '@type': 'string',
    hash: '234234234234',
    delivered: true,
    purchaseIdentifier: 'PRBLGWY7',
    paidBy: {
      name: 'Fulgencio El Fiera',
      type: 'guardian', // guardian, external, member
    },
    products: [
      { name: 'Torneo navidad blah bleh blih', amount: 2 },
    ],
    amount: 120.00, // Need more info (currency, etc...)
    taxAmount: 0,
    status: 0,
    createdAt: '2023-06-20T15:08:54.961Z',
    updatedAt: '2023-06-20T15:08:54.961Z',
  },
  {
    '@context': 'string',
    '@id': 'string',
    '@type': 'string',
    hash: '23456612123123',
    delivered: true,
    purchaseIdentifier: 'PABXGW5D',
    paidBy: {
      name: 'Fulgencio El Pieza',
      type: 'external',
    },
    products: [
      { name: 'Torneo Verano blah bleh blih', amount: 1 },
      { name: 'Torneo Verano Tuberculo', amount: 5 },
    ],
    amount: 120.00,
    taxAmount: 0,
    status: 1,
    createdAt: '2023-06-20T15:08:54.961Z',
    updatedAt: '2023-06-20T15:08:54.961Z',
  },
]

export const soldProducts: ApiEcommerceProducts[] = [
  {
    '@id': 'string',
    '@type': 'string',
    '@context': 'string',
    image: 'https://loremflickr.com/640/400/sport',
    hash: '1234567890HASX',
    name: 'Torneo de Navidul - Acceso anticipado',
    price: 15.35,
    description: 'LALALALALALA Descripción',
    club: 'El club de la comedía',
    tax: {
      '@id': 'string',
      '@type': 'string',
      '@context': 'string',
      hash: 'string',
      name: 'string',
      value: 0,
      type: 1,
    },
    createdAt: '2023-06-20T10:39:35.609Z',
    updatedAt: '2023-06-20T10:39:35.609Z',
    taxAmount: 0,
    totalAmount: 530.80, // Currency data?
    stock: {
      total: 100,
      sold: 4,
    },
    categories: [
      'tournament',
    ],
  },
  {
    '@id': 'string',
    '@type': 'string',
    '@context': 'string',
    image: 'https://loremflickr.com/640/400/sport',
    hash: '1234567890HASD',
    name: 'Torneo de Sofaball - Acceso sedentario',
    price: 11.35,
    description: 'LALALALALALA Descripción',
    club: 'El club de la comedía',
    tax: {
      '@id': 'string',
      '@type': 'string',
      '@context': 'string',
      hash: 'string',
      name: 'string',
      value: 0,
      type: 1,
    },
    createdAt: '2023-06-20T10:39:35.609Z',
    updatedAt: '2023-06-20T10:39:35.609Z',
    taxAmount: 0,
    totalAmount: 1024.30, // Currency data?
    stock: {
      total: 456,
      sold: 222,
    },
    categories: [
      'tournament',
    ],
  },
]
