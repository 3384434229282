import router from '@portal/router'
import { AppModule } from '@libs/fsc/models/AppModule'
import { Ecommerce } from '@portal/modules/Ecommerce'
import { PublicWebsite } from '@portal/modules/PublicWebsite'

export const portalModulesToRegister: Array<AppModule> = [
  Ecommerce,
  PublicWebsite,
]

export function loadAppModules() {
  portalModulesToRegister.forEach(module => module.load(router))
}
