<script setup lang="ts">
import {
  ref,
  onBeforeMount,
} from 'vue'
import { CbAccordion } from '@cluber/carabiner'

import { isProduction } from '@core/utils/env'

// ***
// DEFAULTS
// ***
const packageJSON = ref()

onBeforeMount(() => {
  if (!isProduction) {
    // eslint-disable-next-line import/no-relative-packages
    import('../../../../../../package.json').then(module => {
      packageJSON.value = module.default
    })
  }
})

</script>

<template>
  <div class="min-w-[23rem]">
    <CbAccordion>
      <template #title>
        <p>Dependencies</p>
      </template>
      <template #default>
        <ul
          v-if="packageJSON?.dependencies"
          class="overflow-y-scroll max-h-48"
        >
          <li
            v-for="entry in Object.entries(packageJSON.dependencies)"
            :key="entry[0]"
          >
            {{ entry[0] }}: <strong>{{ entry[1] }}</strong>
          </li>
        </ul>
      </template>
    </CbAccordion>
    <CbAccordion>
      <template #title>
        <p>DEV Dependencies</p>
      </template>
      <template #default>
        <ul
          v-if="packageJSON?.devDependencies"
          class="overflow-y-scroll max-h-48"
        >
          <li
            v-for="entry in Object.entries(packageJSON.devDependencies)"
            :key="entry[0]"
          >
            {{ entry[0] }}: <strong>{{ entry[1] }}</strong>
          </li>
        </ul>
      </template>
    </CbAccordion>
  </div>
</template>
