import { Config } from 'tailwindcss'
import preset from '@cluber/config-brand/tailwind.preset'

const config: Config = {
  darkMode: 'class',
  presets: [
    preset,
  ],
  content: [
    './src/**/*.html',
    './src/**/*.{vue,js,ts,jsx,tsx}',
    './src/App.vue',
    './apps/**/src/**/*.html',
    './apps/**/src/**/*.{vue,js,ts,jsx,tsx}',
    './apps/**/src/App.vue',
    '../../apps/core/src/components/**'
  ],
  theme: {
    extend: {
      screens: {
        short: { 'raw': '(max-height: 800px)' },
      }
    },
  },
  safelist: [
    'absolute',
    'relative',
    'lg:text-4xl',
  ],
}

export default config
