import { ModuleRoutes } from '@core/declarations/router'
import { RoleKind } from '@libs/fsc/types/user'

const AccessControlOverview = () => import('@dashboard/modules/AccessControl/views/Overview/Overview.vue')

const routes: ModuleRoutes = {
  name: 'AccessControl',
  basePath: '/dashboard/manager',
  roleKind: RoleKind.Manager,
  routes: [
    {
      path: '/:clubHash/access-control',
      name: 'accessControlOverview',
      component: AccessControlOverview,
      meta: {
        tabTitle: 'Access control',
      },
    },
  ],
}

export default routes
