import { ref } from 'vue'
import { defineStore } from 'pinia'

export const useAppConfigStore = defineStore('appConfig', () => {
  const isDarkMode = ref<boolean>(false)
  const isStagingBannerHidden = ref<boolean>(false)

  return {
    isDarkMode,
    isStagingBannerHidden,
  }
})
