import { navigateToLegacy } from '@libs/fsc/utils/navigateToLegacy'
import { notProductionEnvironment, isProduction } from '@core/utils/env'

import { NavigationItem } from '../declarations'
import { getParam } from './utils'

const navigationStructureManager: NavigationItem[] = [
  {
    id: 'home',
    label: 'Homepage',
    icon: 'bi-house-fill',
    action: ({ router }) => {
      if (router) {
        router.push({ name: 'managerOverview' })
      }
    },
  },
  {
    id: 'messages',
    label: 'Messages',
    icon: 'bi-envelope-fill',
    submenu: [
      {
        id: 'my-messages',
        label: 'My messages',
        route: {
          name: 'managerMyMessagesList',
        },
      },
      {
        id: 'messages-archived',
        label: 'Archived messages',
        route: {
          name: 'managerArchivedMessagesList',
        },
      },
      {
        id: 'messages-deleted',
        label: 'Deleted messages',
        route: {
          name: 'managerDeletedMessagesList',
        },
      },
      {
        id: 'mailbox-management',
        label: 'Mailbox management',
        route: {
          name: 'managerMailboxManagement',
        },
      },
    ],
  },
  {
    id: 'persons',
    label: 'Persons',
    icon: 'bi-people-fill',
    submenu: [
      {
        id: 'persons-partners',
        label: 'Partners',
        action: ({ router }) => {
          if (router) {
            navigateToLegacy(`/clubes/${getParam(router, 'clubHash')}/socios`)
          }
        },
      },
      {
        id: 'persons-athletes',
        label: 'Athletes',
        action: ({ router }) => {
          if (router) {
            navigateToLegacy(`/clubes/${getParam(router, 'clubHash')}/jugadores`)
          }
        },
      },
      {
        id: 'persons-users',
        label: 'Users',
        action: ({ router }) => {
          if (router) {
            navigateToLegacy(`/clubes/${getParam(router, 'clubHash')}/usuarios`)
          }
        },
      },
    ],
  },
  {
    id: 'clubs',
    label: 'Teams',
    icon: 'bi-shield-shaded',
    action: ({ router }) => {
      if (router) {
        navigateToLegacy(`/clubes/${getParam(router, 'clubHash')}/equipos`)
      }
    },
  },
  {
    id: 'sponsors',
    label: 'Sponsors',
    icon: 'bi-award-fill',
    action: ({ router }) => {
      if (router) {
        navigateToLegacy(`/clubes/${getParam(router, 'clubHash')}/patrocinadores`)
      }
    },
  },
  {
    id: 'campaigns',
    label: 'Campaigns',
    icon: 'bi-megaphone-fill',
    action: ({ router }) => {
      if (router) {
        navigateToLegacy(`/clubes/${getParam(router, 'clubHash')}/items`)
        // router.push({ name: 'dashboardCampaigns', params: { clubHash: getParam(router, 'clubHash') } })
      }
    },
  },
  {
    id: 'bookings',
    label: 'Bookings',
    icon: 'bi-calendar-event-fill',
    isFeature: true,
    submenu: [
      {
        id: 'booking-calendar',
        label: 'Booking Calendars',
        route: {
          name: 'managerBookingCalendars',
        },
      },
      {
        id: 'booking-agenda',
        label: 'Agenda',
        route: {
          name: 'managerBookingAgenda',
        },
      },
      {
        id: 'booking-availability',
        label: 'Availability',
        route: {
          name: 'managerBookingAvailability',
        },
      },
      {
        id: 'booking-pricing-policies',
        label: 'Pricing Policies',
        route: {
          name: 'managerBookingPricingPolicies',
        },
      },
      {
        id: 'booking-link-groups',
        label: 'Link Groups',
        route: {
          name: 'managerBookingLinkGroups',
        },
      },
    ],
  },
  {
    id: 'access-control',
    label: 'Access control',
    icon: 'bi-shop-window',
    action: ({ router }) => {
      if (router) {
        navigateToLegacy(`/clubes/${getParam(router, 'clubHash')}/dashboard`)
      }
    },
  },
]

export default navigationStructureManager
