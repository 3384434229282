import { ModuleRoutes } from '@core/declarations/router'
import { RoleKind } from '@libs/fsc/types/user'

import { member } from '@dashboard/modules/Messages/routes'

const MemberOverview = () => import('@dashboard/modules/Member/views/Overview/MemberOverview.vue')

const routes: ModuleRoutes = {
  name: 'Member',
  basePath: '/dashboard/member',
  roleKind: RoleKind.Member,
  routes: [
    ...member,
    {
      path: '/:userId/:clubHash/overview',
      name: 'memberOverview',
      component: MemberOverview,
      meta: {
        tabTitle: 'Member overview',
      },
    },
  ],
}

export default routes
