<script setup lang="ts">

import {
  ref,
  watch,
} from 'vue'

import { CbModal } from '@cluber/carabiner'

import LandingView from './ModalViews/SupportLanding/SupportLanding.vue'
import ClubInformation from './ModalViews/ClubInformation.vue'
import IncidenceView from './ModalViews/IncidenceView.vue'
import ClaimOrCancellationView from './ModalViews/ClaimOrCancellationView.vue'
import CluberHelp from './ModalViews/CluberHelp.vue'
import ClubFormModalStatus from './ModalViews/ClubFormModalStatus/ClubFormModalStatus.vue'

// ***
// DEFAULTS
// ***
const props = defineProps({
  modelValue: {
    type: Boolean,
    required: true,
  },
})

const emit = defineEmits(['close', 'update:modelValue'])

// ***
// MODAL VIEWS
// ***
type SupportNavigationKey = keyof typeof supportModalViews
type SupportStatusKey = SupportNavigationKey & 'club-info' | 'cancellation-claim'

const LANDING_VIEW = 'landing'
const activeView = ref<SupportNavigationKey>(LANDING_VIEW)
const statusView = ref<'success' | 'warning' | 'error'>()

const supportModalViews = {
  [LANDING_VIEW]: LandingView,
  'club-info': ClubInformation,
  'cancellation-claim': ClaimOrCancellationView,
  'cluber-help': CluberHelp,
  'incidence-view': IncidenceView,
}

const statusModalViews = {
  'club-info': ClubFormModalStatus,
  'cancellation-claim': ClubFormModalStatus,
}

// Reset view on modal close
watch(() => props.modelValue, () => {
  if (!props.modelValue) {
    activeView.value = LANDING_VIEW
    statusView.value = undefined
  }
})

</script>

<template>
  <CbModal
    :model-value="modelValue"
    class="overflow-y-auto"
    content-classes="items-start max-w-[50rem] m-auto"
    :show-back-button="activeView !== LANDING_VIEW && !statusView"
    @back="() => activeView = LANDING_VIEW"
    @update:model-value="(value) => emit('update:modelValue', value)"
  >
    <template #content>
      <Transition
        name="fade"
        mode="out-in"
      >
        <component
          :is="statusModalViews[activeView as SupportStatusKey]"
          v-if="statusView"
          :status="statusView"
          @close="() => emit('update:modelValue', false)"
        />
        <component
          :is="supportModalViews[activeView]"
          v-else
          @navigate="($event: string) => activeView = $event as SupportNavigationKey"
          @success="() => statusView = 'success'"
          @error="() => statusView = 'error'"
        />
      </Transition>
    </template>
  </CbModal>
</template>
