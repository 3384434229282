import router from '@core/router'
import { AppModule } from '@libs/fsc/models/AppModule'
import { Auth } from '@core/modules/Auth'
import { dashboardModulesToRegister } from '@dashboard/modules/loadModules'
import { portalModulesToRegister } from '@portal/modules/loadModules'

const modulesToRegister: Array<AppModule> = [
  Auth,
  ...dashboardModulesToRegister,
  ...portalModulesToRegister,
]

export function loadAppModules() {
  modulesToRegister.forEach(module => module.load(router))
}
