import enUS from './en-US.json'
import esES from './es-ES.json'
import euES from './eu-ES.json'
import glES from './gl-ES.json'
import caES from './ca-ES.json'

const i18nLocales = {
  'en-US': enUS,
  'es-ES': esES,
  'eu-ES': euES,
  'gl-ES': glES,
  'ca-ES': caES,
}

export default i18nLocales
