import { ModuleRoutes } from '@core/declarations/router'

const Default = () => import('@portal/modules/Ecommerce/views/Default/Default.vue')
const CampaignsList = () => import('@portal/modules/Ecommerce/views/CampaignsList/CampaignsList.vue')
const CampaignSummary = () => import('@portal/modules/Ecommerce/views/CampaignSummary/CampaignSummary.vue')
const Tickets = () => import('@portal/modules/Ecommerce/views/Tickets/Tickets.vue')

const routes: ModuleRoutes = {
  name: 'Ecommerce',
  basePath: '/ecommerce',
  routes: [
    {
      path: '',
      name: 'portalCampaigns',
      meta: {
        tabTitle: 'Ecommerce: campaigns list',
        navigationType: 'clean',
      },
      component: Default,
      children: [
        {
          path: 'campaigns/list',
          name: 'portalCampaignsList',
          component: CampaignsList,
          meta: {
            tabTitle: 'Ecommerce: campaigns list',
          },
        },
        {
          path: 'campaign/:campaignId',
          name: 'portalCampaignSummary',
          component: CampaignSummary,
          meta: {
            tabTitle: 'Ecommerce: campaign summary',
          },
        },
        {
          path: 'tickets/list',
          name: 'portalTicketsList',
          component: Tickets,
          meta: {
            tabTitle: '',
          },
        },
      ],
    },
  ],
}

export default routes
