import { createI18n, type I18nOptions } from 'vue-i18n'
import { merge } from 'lodash-es'

import { carabinerLocales } from '@cluber/carabiner'
import dashboardLocales from '@dashboard/locales'
import portalLocales from '@portal/locales'

import i18nLocales from './index'

const options: I18nOptions = {
  locale: process.env.NODE_ENV === 'development' ? 'en-US' : 'es-ES',
  legacy: false,
  fallbackLocale: 'es-ES',
  allowComposition: true,
  messages: merge(
    i18nLocales,
    carabinerLocales,
    dashboardLocales,
    portalLocales,
  ),
  warnHtmlMessage: false,
}

const i18n = createI18n<false, typeof options>(options)

export default i18n
