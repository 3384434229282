<script setup lang="ts">
import {
  ref,
  computed,
  reactive,
  onBeforeUnmount,
} from 'vue'
import { useI18n } from 'vue-i18n'
import { MutationType } from 'pinia'

import {
  CbPassword,
  CbButton,
  CbModalStatus,
  ModalViewStatus,
} from '@cluber/carabiner'

import PageTitle from '@dashboard/components/PageTitle/PageTitle.vue'

import { patchUserChangePassword } from '@core/modules/User/api'
import { useApiUserStore } from '@core/modules/User/store'
import { type PatchUserChangePasswordRequest, PatchUserChangePasswordErrorMessages } from '@core/modules/User/declarations'
import { ApiRequestStatus, type ErrorStoreResponse } from '@core/store/apiRequest/declarations'

import { type PasswordFieldValidity, ChangePasswordForm } from './declarations'

// ***
// DEFAULT
// ***
const { t } = useI18n()

// ***
// REQUEST
// ***
const isChangingPassword = ref(false)
const statusView = ref()
const requestErrorMessage = ref<string>()
const apiUserStore = useApiUserStore()

const {
  sendRequest,
  $subscribe: subscribeUserStore,
  checkRequestStatus,
  convertApiDataFromStore,
} = apiUserStore

async function requestChangePassword(data: PatchUserChangePasswordRequest['data']) {
  isChangingPassword.value = true
  await sendRequest<PatchUserChangePasswordRequest>({
    endpointConfig: patchUserChangePassword,
    requestConfig: {
      data,
    },
  })
}

const unsubscribeUserStore = subscribeUserStore((mutation) => {
  if (mutation.type === MutationType.patchObject) {
    const requestChangePasswordStatus = checkRequestStatus(patchUserChangePassword.storeKey, mutation)
    if (requestChangePasswordStatus === ApiRequestStatus.Success) {
      isChangingPassword.value = false
      statusView.value = ModalViewStatus.Success
    }

    if (requestChangePasswordStatus === ApiRequestStatus.Error) {
      const { data } = convertApiDataFromStore<ErrorStoreResponse>(patchUserChangePassword.storeKey)
      requestErrorMessage.value = data?.error.detail || 'Something went wrong'
      statusView.value = ModalViewStatus.Error
      isChangingPassword.value = false
    }
  }
})

onBeforeUnmount(unsubscribeUserStore)

// ***
// FORM
// ***

const passwordForm = ref()

function submitForm() {
  passwordForm.value.node.submit()
}

function submitHandler(form: ChangePasswordForm) {
  isChangingPassword.value = true
  const { changePasswordForm } = form
  const data = {
    currentPassword: changePasswordForm.currentPassword,
    newPassword: changePasswordForm.newPassword,
  }
  requestChangePassword(data)
}

// ***
// VALIDATION
// ***

const fieldValidity: Record<string, PasswordFieldValidity> = reactive({
  minMaxLength: 'invalid',
  atLeastOneNumber: 'invalid',
  atLeastOneUppercase: 'invalid',
  atLeastOneLowercase: 'invalid',
  atLeastOneSymbol: 'invalid',
})

function resetFieldValidity() {
  fieldValidity.minMaxLength = 'invalid'
  fieldValidity.atLeastOneNumber = 'invalid'
  fieldValidity.atLeastOneUppercase = 'invalid'
  fieldValidity.atLeastOneLowercase = 'invalid'
  fieldValidity.atLeastOneSymbol = 'invalid'
}

function checkForValidRequirement(value: string) {
  if (!value) {
    resetFieldValidity()
  } else {
    fieldValidity.minMaxLength = ((value.length >= 8) && (value.length <= 24)) ? 'valid' : 'invalid'
    fieldValidity.atLeastOneNumber = value.match(/\d/g) ? 'valid' : 'invalid'
    fieldValidity.atLeastOneUppercase = value.match(/[A-Z]/g) ? 'valid' : 'invalid'
    fieldValidity.atLeastOneLowercase = value.match(/[a-z]/g) ? 'valid' : 'invalid'
    fieldValidity.atLeastOneSymbol = value.match(/[!@#$%^&*)(+=._-]/g) ? 'valid' : 'invalid'
  }
}

function changeIconByValidity(valid: PasswordFieldValidity) {
  if (valid === 'invalid') return 'text-danger-300 bi bi-x'
  return valid ? 'bi bi-check' : 'bi bi-x'
}

function returnClassByValidity(valid: PasswordFieldValidity) {
  if (valid === 'invalid') return 'text-secondary-700'
  return valid === 'valid' ? 'text-success-500 font-bold' : 'text-danger-300 font-bold'
}

// ***
// ERROR MESSAGES
// ***

const setErrorMessage = computed(() => {
  if (requestErrorMessage.value === PatchUserChangePasswordErrorMessages.BadCurrentPassword) {
    return t("The password you set as current password doesn't match with the one we have registered")
  }

  return requestErrorMessage.value
})

</script>

<template>
  <CbModalStatus
    v-if="statusView"
    :status="statusView"
  >
    <template #title="{ status }">
      {{ status === ModalViewStatus.Success ? t('Password changed!') : t('Something went wrong') }}
    </template>
    <template #description="{ status }">
      <p>
        {{ status === ModalViewStatus.Success ? t('Your new password has been set correctly') : setErrorMessage }}
      </p>

      <CbButton
        v-if="status === ModalViewStatus.Error"
        mode="filled"
        color="primary"
        class="mt-3"
        @click="() => {
          statusView = undefined
          resetFieldValidity()
        }"
      >
        {{ t('Try again') }}
      </CbButton>
    </template>
  </CbModalStatus>
  <div v-else>
    <PageTitle>
      {{ t('Change password') }}
    </PageTitle>

    <FormKit
      ref="passwordForm"
      type="form"
      :classes="{
        form: 'space-y-5 p-[2px]',
      }"
      :actions="false"
      @submit="submitHandler"
    >
      <FormKit
        id="changePasswordForm"
        name="changePasswordForm"
        type="group"
      >
        <CbPassword
          id="currentPassword"
          name="currentPassword"
          data-testid="currentPassword"
          :label="t('Current password')"
          validation="required"
        />
        <CbPassword
          id="newPassword"
          name="newPassword"
          data-testid="newPassword"
          :delay="0"
          :label="t('New password')"
          validation="required|contains_alphanumeric|contains_lowercase|contains_uppercase|contains_symbol|length:8,24"
          @input="checkForValidRequirement"
        />
        <ul class="border border-base-300 rounded p-5 mt-8 text-sm">
          <li
            data-testid="fieldValidity-minMax"
            :class="returnClassByValidity(fieldValidity.minMaxLength)"
          >
            <i :class="changeIconByValidity(fieldValidity.minMaxLength)" />
            {{ t('Password length between 8 and 24 characters') }}
          </li>
          <li
            data-testid="fieldValidity-atLeastNumber"
            :class="returnClassByValidity(fieldValidity.atLeastOneNumber)"
          >
            <i :class="changeIconByValidity(fieldValidity.atLeastOneNumber)" />
            {{ t('At least one number') }}
          </li>
          <li
            data-testid="fieldValidity-atLeastUppercase"
            :class="returnClassByValidity(fieldValidity.atLeastOneUppercase)"
          >
            <i :class="changeIconByValidity(fieldValidity.atLeastOneUppercase)" />
            {{ t('At least one uppercase character') }}
          </li>
          <li
            data-testid="fieldValidity-atLeastLowercase"
            :class="returnClassByValidity(fieldValidity.atLeastOneLowercase)"
          >
            <i :class="changeIconByValidity(fieldValidity.atLeastOneLowercase)" />
            {{ t('At least one lowercase character') }}
          </li>
          <li
            data-testid="fieldValidity-atLeastSymbol"
            :class="returnClassByValidity(fieldValidity.atLeastOneSymbol)"
          >
            <i :class="changeIconByValidity(fieldValidity.atLeastOneSymbol)" />
            {{ t('At least one symbol') }} <span class="bg-base-100 rounded border border-base-300 px-1">!@#$%^&*)(+=._-</span>
          </li>
        </ul>
        <CbPassword
          id="confirmNewPassword"
          name="confirmNewPassword"
          :label="t('Confirm new password')"
          validation="required|confirm:newPassword|contains_alphanumeric|contains_lowercase|contains_uppercase|contains_symbol|length:8,24"
        />
      </FormKit>
    </FormKit>
    <CbButton
      mode="filled"
      color="primary"
      class="mt-8 w-full"
      :loading="isChangingPassword"
      @click="submitForm"
      @keydown.enter="submitForm"
    >
      {{ t('Save') }}
    </CbButton>
  </div>
</template>
