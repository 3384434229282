<script setup lang="ts">
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'

import {
  CbCard,
  CbDividerLine,
} from '@cluber/carabiner'

import { RoleKind } from '@libs/fsc/types/user'
import { zoho, cluber } from '@dashboard/config'

import claim from '@dashboard/assets/images/claim.svg'
import information from '@dashboard/assets/images/information.svg'
import help from '@dashboard/assets/images/help.svg'
import bug from '@dashboard/assets/images/bug.svg'
// ***
// DEFAULTS
// ***
const { t } = useI18n()
const emit = defineEmits(['navigate'])

const route = useRoute()
const isManager = computed(() => route.meta.roleKind === RoleKind.Manager)

// ***
// SUPPORT MODAL BUTTONS
// ***

const supportModalButtons = [
  {
    showButton: !isManager.value,
    icon: information,
    label: t('Club Information'),
    summary: t('I need information about the club (schedules, rules, materials, payments...)'),
    navigateTo: 'club-info',
    buttonPrompt: t('Fill form'),
  },
  {
    showButton: !isManager.value,
    icon: claim,
    label: t('Cancellation or claim'),
    summary: t('I want to unsubscribe or I have received a charge that is not applicable'),
    navigateTo: 'cancellation-claim',
    buttonPrompt: t('Start request'),
  },
  {
    showButton: true,
    icon: help,
    label: t('Cluber Help'),
    summary: t('I have a question or need help to perform some action or configuration in Cluber'),
    navigateTo: 'cluber-help',
    buttonPrompt: t('Start asking'),
  },
  {
    showButton: true,
    icon: bug,
    label: t('Something is not working'),
    summary: t('Something is not working properly and/or I want to report an issue'),
    navigateTo: 'incidence-view',
    buttonPrompt: t('Let us know'),
  },
]

</script>

<template>
  <div class="flex flex-col">
    <h3 class="font-display font-semibold text-3xl mb-8">
      {{ t('What can we help you with?') }}
    </h3>
    <div class="grid grid-cols-1 md:grid-cols-2 gap-[2rem]">
      <template
        v-for="buttonInfo in supportModalButtons"
        :key="buttonInfo.navigateTo"
      >
        <CbCard
          v-if="buttonInfo.showButton"
          data-testid="imageCardAction"
          class="flex flex-col justify-between cursor-pointer border border-base-200 hover:border-primary-500 p-5"
          @click.prevent="() => emit('navigate', buttonInfo.navigateTo)"
          @keydown.enter="() => emit('navigate', buttonInfo.navigateTo)"
        >
          <div class="flex flex-col items-center">
            <img
              :src="buttonInfo.icon"
              :alt="buttonInfo.label"
              class="h-[8rem] w-[8rem]"
            />
            <h3 class="font-display font-bold text-xl mb-1 text-center">
              {{ buttonInfo.label }}
            </h3>
            <p
              v-if="buttonInfo.summary"
              class="text-center text-base-700"
            >
              {{ buttonInfo.summary }}
            </p>
          </div>
          <div class="flex flex-col items-center">
            <CbDividerLine class="!my-3 justify-self-end" />
            <a
              v-if="buttonInfo.buttonPrompt"
              class="font-bold text-primary-500 flex"
            >
              <span>{{ buttonInfo.buttonPrompt }}</span>
              <i class="bi bi-arrow-right ml-2" />
            </a>
          </div>
        </CbCard>
      </template>
    </div>
    <i18n-t
      class="max-w-[23rem] text-center self-center mt-[2.5rem]"
      keypath="You can also check our {tutorials} and our {faq} section"
      tag="p"
    >
      <template #tutorials>
        <a
          class="font-bold text-primary-500 hover:underline cursor-pointer"
          :href="cluber.cluberTutorialsUrl"
          target="_blank"
          rel="noopener noreferrer"
        >
          {{ t('Tutorials') }}
        </a>
      </template>
      <template #faq>
        <a
          class="font-bold text-primary-500 hover:underline cursor-pointer"
          :href="zoho.cluberFaqUrl"
          target="_blank"
          rel="noopener noreferrer"
        >
          {{ t('Frequently Asked Questions') }}
        </a>
      </template>
    </i18n-t>
  </div>
</template>
