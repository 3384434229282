<script setup lang="ts">
import {
  ref,
  watch,
  computed,
  onMounted,
  onBeforeUnmount,
  type Ref,
} from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute, useRouter } from 'vue-router'
import { CbLogo } from '@cluber/carabiner'
import { onClickOutside } from '@vueuse/core'
import { MutationType } from 'pinia'
import { clone } from 'lodash-es'

import { ApiRequestStatus } from '@core/store/apiRequest/declarations'
import { getClubEnabledFeatures } from '@core/modules/Club/api'
import { useApiClubStore } from '@core/modules/Club/store'
import { type GetClubEnabledFeaturesRequest, type ClubEnabledFeatures } from '@core/modules/Club/declarations'
import { RoleKind } from '@libs/fsc/types/user'
import { navigateToLegacy } from '@libs/fsc/utils/navigateToLegacy'

import { useGetLegacyPeopleGroupByClub } from '@dashboard/modules/ProfileSelector/composables'
import { useUnreadMessages } from '@dashboard/modules/Messages/composables/useUnreadMessages'

import SupportModal from '@dashboard/components/Support/SupportModal.vue'

import NavSubmenu from './NavSubmenu/NavSubmenu.vue'
import NavItem from './NavItem/NavItem.vue'
import NavUserData from './NavUserData/NavUserData.vue'

import {
  navigationStructureManager,
  navigationStructureGuardian,
  navigationStructureMember,
  lowerNavigation,
} from './NavigationStructure/index'
import { NavigationItem } from './declarations'

// ***
// DEFAULTS
// ***
const router = useRouter()
const route = useRoute()
const { t } = useI18n()
const props = defineProps({
  responsiveMenu: {
    type: Boolean,
  },
})

const {
  currentUser,
} = useGetLegacyPeopleGroupByClub()
const isManager = computed(() => route.meta.roleKind === RoleKind.Manager)

// ***
// COMMON
// ***
const isDashboardNav = computed(() => route.meta.navigationType === 'dashboard')
const nav: Ref<HTMLElement | undefined> = ref()
const activeMenus: Ref<string[]> = ref([])

function isActive(id: string): boolean {
  return activeMenus.value.includes(id)
}

function toggleMenu(id: string): void {
  if (isActive(id)) {
    activeMenus.value = activeMenus.value.filter(itemId => itemId !== id)
  } else {
    activeMenus.value.push(id)
  }
}

// ***
// ENABLED FEATURES
// ***
const enabledFeatures = ref()
const isFetchingFeatures = ref()
const apiClubStore = useApiClubStore()
const {
  checkRequestStatus,
  sendRequest,
  convertApiDataFromStore,
  $subscribe: subscribeClubStore,
} = apiClubStore

function requestEnabledClubFeatures() {
  if (!route.params.clubHash) return
  isFetchingFeatures.value = true
  sendRequest<GetClubEnabledFeaturesRequest>({
    endpointConfig: getClubEnabledFeatures,
    requestConfig: {
      params: {
        clubHash: route.params.clubHash as string,
      },
    },
  })
}

watch(() => route.params.clubHash, (current) => {
  if (current) requestEnabledClubFeatures()
}, { immediate: true })

const unsubscribeGetEnabledFeatures = subscribeClubStore((mutation) => {
  if (mutation.type === MutationType.patchObject) {
    const requestEnabledFeaturesStatus = checkRequestStatus(getClubEnabledFeatures.storeKey, mutation)

    if (requestEnabledFeaturesStatus === ApiRequestStatus.Success) {
      const { data } = convertApiDataFromStore<ClubEnabledFeatures>(getClubEnabledFeatures.storeKey)
      if (data) enabledFeatures.value = data
      isFetchingFeatures.value = false
    }

    if (requestEnabledFeaturesStatus === ApiRequestStatus.Error) {
      isFetchingFeatures.value = false
    }
  }
})

onBeforeUnmount(unsubscribeGetEnabledFeatures)

function filterNavigationByEnabledFeatures(navigation: NavigationItem[]) {
  // eslint-disable-next-line consistent-return, array-callback-return
  const filteredNavigation = navigation.filter((navItem) => {
    if (navItem.isFeature === undefined) return navItem
    if (navItem.isFeature && enabledFeatures.value?.includes(navItem.id)) {
      return navItem
    }
  })
  return filteredNavigation
}

// ***
// SUBSIDIES
// ***

const processedSubsidiesNavigation = computed(() => {
  const isEmptySubsidies = !currentUser.value?.subsidies || currentUser.value.subsidies.length === 0
  if (isEmptySubsidies) {
    return navigationStructureManager
  }

  const subsidiesMenuItem : NavigationItem = {
    id: 'subsidies',
    label: 'Subsidies',
    icon: 'bi bi-bank2',
    submenu: [],
  }

  subsidiesMenuItem.submenu = currentUser.value.subsidies?.map(
    (subsidy) => ({
      id: `subsidies-${subsidy.id}`,
      label: subsidy.name,
      action: () => {
        if (router) {
          navigateToLegacy(`/clubes/${route.params.clubHash as string}/subvenciones/${subsidy.id}`)
        }
      },
    }),
  )

  // Placed as second item of list
  const subsidiesNavIndex = 1
  return navigationStructureManager.toSpliced(subsidiesNavIndex, 0, subsidiesMenuItem)
})

// ***
// RESPONSIVE
// ***
const isOpen = ref(false)

function closeNavigation() {
  isOpen.value = false
}

onClickOutside(nav, event => {
  const element = event.target as HTMLElement
  if (element && element.id !== 'navMenuIcon') {
    closeNavigation()
  }
})

watch(() => props.responsiveMenu, () => {
  if (isOpen.value) {
    closeNavigation()
  }
})

function setNavWidth() {
  const navWidth = nav.value?.getBoundingClientRect().width
  document.documentElement.style.setProperty('--cluber-app-nav-width', `${navWidth || 0}px`)
}

watch(nav, () => {
  if ((nav.value?.getBoundingClientRect().width !== 0) && isDashboardNav.value) {
    setNavWidth()
  }
})

onMounted(() => {
  setNavWidth()
})

// ***
// MODALS
// ***
const navItemSelected = ref('')
const showModal = ref(false)

const modalByChosenNav = {
  support: SupportModal,
}

const activeModal = computed(() => {
  const modalKey = navItemSelected.value
  if (modalKey in modalByChosenNav) {
    return modalByChosenNav[modalKey as keyof typeof modalByChosenNav]
  }
  return undefined
})

// ***
// UNREAD MESSAGES
// ***
const { unreadMessages } = useUnreadMessages({ shouldPollUnreadMessages: true })

// ***
// NAVIGATION
// ***

function navigationTypeByRole(): NavigationItem[] {
  const navigationTypes = {
    [RoleKind.Member]: navigationStructureMember,
    [RoleKind.Guardian]: navigationStructureGuardian,
    [RoleKind.Manager]: filterNavigationByEnabledFeatures(processedSubsidiesNavigation.value),
  }
  if (!route.meta.roleKind) return []
  return navigationTypes[route.meta.roleKind as keyof typeof navigationTypes]
}

const navigationStructure = computed(() => {
  const navigation = clone(navigationTypeByRole())

  // MESSAGES BADGE
  const messagesNav = navigation.find((item) => item.id === 'messages')

  if (messagesNav) {
    messagesNav.badge = unreadMessages.value && unreadMessages.value > 0
      ? `${unreadMessages.value}`
      : undefined
  }
  return navigation
})

function navigateTo(item: NavigationItem) {
  navItemSelected.value = item.id
  if (item.action) item.action({ router, currentUser: currentUser.value })
  if (item.route) router.push(item.route)
  closeNavigation()
}

function handleNavItemClicked(itemSelected: string) {
  navItemSelected.value = itemSelected
  showModal.value = navItemSelected.value in modalByChosenNav
}
</script>

<template>
  <div
    v-if="responsiveMenu && isDashboardNav"
    data-testid="navigationResponsiveMenu"
    class="fixed top-0 left-0 z-40 flex items-center w-full h-16 transition-all duration-300 bg-white shadow dark:bg-base-1000"
    :style="{
      left: isOpen ? 'var(--cluber-app-nav-width)' : 0,
    }"
  >
    <button
      type="button"
      class="fixed ml-3 left-1"
      data-testid="mobileToggleOpenButton"
      @click="isOpen = !isOpen"
    >
      <i
        id="navMenuIcon"
        class="text-3xl bi bi-list"
      />
    </button>

    <img
      src="https://s3.eu-west-1.amazonaws.com/images.cluber.es/cluber_logo.4d4c091f.png"
      alt="cluber logo"
      :class="[
        'mx-auto w-32 p-3 left-2/4 absolute transform -translate-x-1/2 transition-opacity duration-200',
        isOpen ? 'opacity-0' : 'opacity-100',
      ]"
    >
  </div>
  <nav
    v-if="isDashboardNav && !isFetchingFeatures"
    ref="nav"
    :class="[
      'flex flex-col fixed top-0 left-0 h-screen w-52 max-w-52 overflow-scroll transition-all duration-300 z-40',
      'bg-base-100 dark:bg-base-1000 border-r dark:border-base-700 border-base-300 text-secondary-300 dark:text-white',
      { 'nav_responsive-position': responsiveMenu && !isOpen },
    ]"
  >
    <NavUserData />
    <ul>
      <template
        v-for="item in navigationStructure"
        :key="item.label"
      >
        <NavItem
          v-if="item.route || item.action"
          :id="item.id"
          :icon="item.icon"
          :action="() => {
            if (item.action) {
              item.action({ router, currentUser })
              closeNavigation()
            }
          }"
          :badge="item.badge"
          @nav-item-clicked="handleNavItemClicked"
        >
          {{ t(item.label) }}
        </NavItem>
        <template v-if="item.submenu">
          <NavSubmenu
            :nav-item="item"
            :submenu-items="item.submenu"
            :is-active="isActive(item.id)"
            @toggle="toggleMenu($event)"
            @navigate="navigateTo"
          >
            {{ t(item.label) }}
          </NavSubmenu>
        </template>
      </template>
    </ul>

    <ul class="mt-auto">
      <template v-if="isManager">
        <template
          v-for="item in lowerNavigation"
          :key="item.label"
        >
          <NavItem
            v-if="item.route || item.action"
            :id="item.id"
            :action="item.action"
            @nav-item-clicked="handleNavItemClicked"
          >
            {{ t(item.label) }}
          </NavItem>
          <template v-if="item.submenu">
            <NavSubmenu
              :nav-item="item"
              :submenu-items="item.submenu"
              :is-active="isActive(item.id)"
              @toggle="toggleMenu($event)"
              @navigate="navigateTo"
            >
              {{ t(item.label) }}
            </NavSubmenu>
          </template>
        </template>
      </template>
    </ul>

    <CbLogo class="w-32 p-3" />
    <component
      :is="activeModal"
      v-if="activeModal"
      v-model="showModal"
    />
  </nav>
</template>

<style>
.nav_responsive-position {
  left: calc(var(--cluber-app-nav-width) * -1) !important;
}

nav::-webkit-scrollbar {
  display: none;
}

nav {
  scrollbar-width: none;
  -ms-overflow-style: none;
}
</style>
