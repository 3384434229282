import { navigateToLegacy } from '@libs/fsc/utils/navigateToLegacy'

import { NavigationItem } from '../declarations'
import { getParam } from './utils'

const navigationStructureGuardian: NavigationItem[] = [
  {
    id: 'home',
    label: 'Homepage',
    icon: 'bi-house-fill',
    action: ({ router, currentUser }) => {
      if (router && currentUser?.id) {
        navigateToLegacy(`/padres/${currentUser.id}/clubes/${getParam(router, 'clubHash')}/area-privada`)
      }
    },
  },
  {
    id: 'identification',
    label: 'My ID',
    icon: 'bi-person-vcard-fill',
    action: ({ router, currentUser }) => {
      if (router && currentUser?.id) {
        navigateToLegacy(`/padres/${currentUser?.id}/clubes/${getParam(router, 'clubHash')}/carnet`)
      }
    },
  },
  {
    id: 'messages',
    label: 'Messages',
    icon: 'bi-envelope-fill',
    submenu: [
      {
        id: 'my-messages',
        label: 'My messages',
        route: {
          name: 'guardianMyMessagesList',
        },
      },
      {
        id: 'messages-archived',
        label: 'Archived messages',
        route: {
          name: 'guardianArchivedMessagesList',
        },
      },
    ],
  },
  {
    id: 'payments',
    label: 'Payments',
    icon: 'bi-bank2',
    action: ({ router, currentUser }) => {
      if (router && currentUser?.id) {
        navigateToLegacy(`/padres/${currentUser?.id}/clubes/${getParam(router, 'clubHash')}/cargos`)
      }
    },
  },
  {
    id: 'support',
    label: 'Support',
    icon: 'bi-life-preserver',
    action: ({ router }) => {
    },
  },
]

export default navigationStructureGuardian
