<script setup lang="ts">
import {
  ref,
  inject,
  onBeforeMount,
} from 'vue'
import { CbSwitch } from '@cluber/carabiner'

import { appConfigInject } from '../declarations'
// ***
// DEFAULTS
// ***
const devToolsState = inject(appConfigInject)

// ***
// DARK MODE
// ***
const isDarkMode = ref(false)

function toggleDarkMode(isActive: boolean) {
  if (!devToolsState) return
  // Update darkmode state for whole app
  devToolsState.appConfig.$patch({
    isDarkMode: isActive,
  })

  // Update darkmode for switch input
  isDarkMode.value = isActive

  // Update darkmode in localStorage for future loadings
  devToolsState.appConfigStorage.value.isDarkMode = isActive

  // Update DOM for darkmode
  if (isActive) {
    document.getElementsByTagName('html')[0].classList.add('dark')
    document.getElementsByTagName('body')[0].classList.add('dark:bg-base-900', 'dark:text-white')
  } else {
    document.getElementsByTagName('html')[0].classList.remove('dark')
    document.getElementsByTagName('body')[0].classList.remove('dark:bg-base-900', 'dark:text-white')
  }
}

onBeforeMount(() => {
  if (devToolsState?.appConfigStorage.value.isDarkMode) {
    toggleDarkMode(true)
  }
})
</script>

<template>
  <h2>Dark Mode</h2>
  <CbSwitch
    v-model="isDarkMode"
    class="mt-5"
    name="switch-input"
    @input="(event: Event) => toggleDarkMode((event.target as HTMLInputElement).checked)"
  >
    Toggle dark mode <strong>{{ isDarkMode ? 'OFF' : 'ON' }}</strong>
  </CbSwitch>
  <hr class="mt-5">
</template>
