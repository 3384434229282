import { ModuleRoutes } from '@core/declarations/router'
import { RoleKind } from '@libs/fsc/types/user'

import { guardian } from '@dashboard/modules/Messages/routes'

const GuardianOverview = () => import('@dashboard/modules/Guardian/views/Overview/GuardianOverview.vue')

const routes: ModuleRoutes = {
  name: 'Guardian',
  basePath: '/dashboard/guardian',
  roleKind: RoleKind.Guardian,
  routes: [
    ...guardian,
    {
      path: '/:userId/:clubHash/overview',
      name: 'guardianOverview',
      component: GuardianOverview,
      meta: {
        tabTitle: 'Guardian home',
      },
    },
  ],
}

export default routes
