export const apiURL = {
  main: import.meta.env.VITE_ENDPOINT_MAIN,
  legacy: import.meta.env.VITE_ENDPOINT_LEGACY,
  origin: window.location.origin,
}

export const allowMSW = import.meta.env.VITE_ALLOW_MSW === 'allow'

export const cluberLegacy = {
  baseUrl: import.meta.env.VITE_CLUBER_LEGACY_URL,
}
