import {
  type RouteLocation,
  useRouter,
} from 'vue-router'

import { useCluberLocalStorage } from '@cluber/tools-composables'

import { useApiAuthStore } from '@core/modules/Auth/store'
import { isJWTExpired } from '@core/store/apiRequest/apiRequest'
import { type LocalStorageAuth } from '@core/modules/Auth/declarations'

export function handleRedirects(to: RouteLocation, from: RouteLocation, token: string) {
  // KEEP THE FOLLOWING LINE UNTIL LEGACY MIGRATION (FORCES REDIRECT TO LEGACY)
  if (!useRouter().getRoutes().find(route => route.path.includes(to.fullPath))) return true
  if (to.meta.isPublic) return true
  // Note that some redirects on login should be triggered from Login.vue component

  // If no token is set and is accessing the base path, redirect to login
  // wihtout warning about token expiration
  if (!token && from.path === '/') {
    return {
      name: 'authLogin',
      query: {
        from: window.location.pathname,
      },
    }
  }

  // If token is not set or not present on localStorage and the route where you trying to navigate is not login directly
  // the route is saved as query and after succesfull login you will be redirected
  const invalidToken = !token ? true : isJWTExpired(token)

  if (invalidToken && (to.name !== 'authLogin') && from.name !== 'authLogin') {
    if (to.name === 'authLogout') return { name: 'authLogin' } // Redirect to login, after logout
    return {
      name: 'authLogin',
      query: {
        from: window.location.pathname,
        expiredToken: 'true',
      },
    }
  }

  // If you are already logged into cluber and try to navigate to login page this autoredirects you to the determined paths
  // if the user is trying to logout this redirect is avoided
  if (!isJWTExpired(token) && (to.name === 'authLogin') && (from.name !== 'authLogin')) {
    return { name: 'profileSelector' }
  }

  // Don't break navigation if any of the previous cases are false
  return true
}

export async function userAccessValidation(to: RouteLocation, from: RouteLocation) {
  const { storage } = useCluberLocalStorage<Omit<LocalStorageAuth, 'currentProfile'>>({
    auth: { token: '', userIdentifier: '' },
  }, { mergeDefaults: true })

  // LEGACY
  if (to.query?.identifier) {
    storage.value.auth.token = (to.query?.identifier as string) // THIS LINE IS ONLY REQUIRED FOR LEGACY PURPOSES REMOVE WHEN FULL MIGRATION
  }
  // LEGACY

  const authStore = useApiAuthStore()
  const stateAuthToken = authStore.$state?.postAuthToken
  const currentToken = stateAuthToken?.token || storage.value.auth.token
  return handleRedirects(to, from, currentToken)
}
