import { type RouteRecordRaw } from 'vue-router'
import { type ModuleRoute } from '@core/declarations/router'
import { UserKind } from '@libs/fsc/types/user'

const MessagesOverview = () => import('@dashboard/modules/Messages/views/MessagesOverview/MessagesOverview.vue')
const MessagesList = () => import('@dashboard/modules/Messages/views/MessagesOverview/MessagesList/MessagesList.vue')
const MessagesListDeleted = () => import('@dashboard/modules/Messages/views/MessagesOverview/MessagesListDeleted/MessagesListDeleted.vue')
const MessagesListArchived = () => import('@dashboard/modules/Messages/views/MessagesOverview/MessagesListArchived/MessagesListArchived.vue')
const MessagesThread = () => import('@dashboard/modules/Messages/views/MessagesThread/MessagesThread.vue')
const MailboxManagement = () => import('@dashboard/modules/Messages/views/MailboxManagement/MailboxManagement.vue')

type ExcludedUserKinds = UserKind.Player | UserKind.External
type MessageRoutesUserKind = Exclude<UserKind, ExcludedUserKinds>

function generateSharedRoutes(role: MessageRoutesUserKind): RouteRecordRaw[] {
  const routeArchivedByRole = {
    [UserKind.Guardian]: 'guardianArchivedMessagesList',
    [UserKind.Member]: 'memberArchivedMessagesList',
    [UserKind.Manager]: 'managerArchivedMessagesList',
  }

  const routeMessagesByRole = {
    [UserKind.Guardian]: 'guardianMyMessagesList',
    [UserKind.Member]: 'memberMyMessagesList',
    [UserKind.Manager]: 'managerMyMessagesList',
  }

  return ([
    {
      path: ':clubHash/my-messages',
      name: routeMessagesByRole[role],
      component: MessagesList,
      meta: {
        tabTitle: 'My messages',
      },
    },
    {
      path: ':clubHash/archived-messages',
      name: routeArchivedByRole[role],
      component: MessagesListArchived,
      meta: {
        tabTitle: 'Archived messages',
      },
    },
  ])
}

function generateMessageThread(role: MessageRoutesUserKind): RouteRecordRaw {
  const routeByRole = {
    [UserKind.Guardian]: 'guardianMessageThread',
    [UserKind.Member]: 'memberMessageThread',
    [UserKind.Manager]: 'managerMessageThread',
  }

  return ({
    path: ':clubHash/thread/:threadHash',
    name: routeByRole[role],
    component: MessagesThread,
    meta: {
      tabTitle: 'Message thread',
    },
  })
}

export const guardian: ModuleRoute[] = [
  {
    path: '/messages',
    name: 'GuardianMessages',
    component: MessagesOverview,
    children: [
      ...generateSharedRoutes(UserKind.Guardian),
      generateMessageThread(UserKind.Guardian),
    ],
  },
]

export const member: ModuleRoute[] = [
  {
    path: '/messages',
    name: 'MemberMessages',
    component: MessagesOverview,
    children: [
      ...generateSharedRoutes(UserKind.Member),
      generateMessageThread(UserKind.Member),
    ],
  },
]

export const manager: ModuleRoute[] = [
  {
    path: '/messages',
    name: 'ManagerMessages',
    component: MessagesOverview,
    children: [
      ...generateSharedRoutes(UserKind.Manager),
      generateMessageThread(UserKind.Manager),
      {
        path: ':clubHash/mailbox-management',
        name: 'managerMailboxManagement',
        component: MailboxManagement,
        meta: {
          tabTitle: 'Mailbox management',
        },
      },
      {
        path: ':clubHash/deleted-messages',
        name: 'managerDeletedMessagesList',
        component: MessagesListDeleted,
        meta: {
          tabTitle: 'Deleted messages',
        },
      },
    ],
  },
]
