import { type InjectionKey, type Ref } from 'vue'
import { type Store } from 'pinia'

export interface AppConfig {
  isDarkMode?: boolean,
  isStagingBannerHidden?: boolean,
}

export interface AppConfigInjectionData {
  appConfig: Store<'appConfig', AppConfig>,
  appConfigStorage: Ref<AppConfig>,
}

export const appConfigInject: InjectionKey<AppConfigInjectionData> = Symbol('devToolsState')
