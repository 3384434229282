import { ModuleRoutes } from '@core/declarations/router'

const Login = () => import('@core/modules/Auth/views/Login/Login.vue')

const routes: ModuleRoutes = {
  name: 'Auth',
  basePath: '/auth',
  routes: [
    {
      path: '/login',
      name: 'authLogin',
      component: Login,
      meta: {
        tabTitle: 'Access',
        navigationType: 'clean',
      },
    },
    {
      path: '/logout',
      name: 'authLogout',
      component: Login,
      meta: {
        tabTitle: 'Access',
        navigationType: 'clean',
      },
    },
  ],
}

export default routes
