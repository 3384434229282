import { ApiPublicPortalComponentContent } from '@portal/modules/PublicWebsite/declarations'

export const componentsList: ApiPublicPortalComponentContent[] = [
  {
    '@id': '/api/clubs/1/portal/components/3',
    '@type': 'PortalComponent',
    id: 2,
    name: 'tickets',
    type: 'link',
    core: true,
    enabled: false,
    properties: {
      href: 'https://www.google.es',
    },
    order: 2,
    highlighted: false,
  },
  {
    '@id': '/api/clubs/1/portal/components/3',
    '@type': 'PortalComponent',
    id: 3,
    name: 'Canal de Youtube',
    type: 'link',
    core: false,
    enabled: false,
    properties: {
      href: 'https://www.google.es',
    },
    order: 3,
    highlighted: true,
  },
  {
    '@id': '/api/clubs/1/portal/components/3',
    '@type': 'PortalComponent',
    id: 10,
    name: 'TikTok',
    core: false,
    enabled: true,
    type: 'link',
    properties: {
      href: 'https://www.google.es',
    },
    order: 1,
    highlighted: false,
  },
]
