import { reactive } from 'vue'
import qs from 'qs'
import { compile } from 'path-to-regexp'

import { useStorage } from '@vueuse/core'

import { apiURL } from '@core/config'
import { isProduction } from '@core/utils/env'
import { AllowedRequestConfigProperties } from '@core/store/apiRequest/declarations'

type KeyOfTypeApiUrl = keyof typeof apiURL
type RecentUsedEndpoints = Record<KeyOfTypeApiUrl, string[]> | Record<string, never>
interface StorageEndpoints {
  endpoints: typeof apiURL,
  recentUsedEndpoints: RecentUsedEndpoints,
}

const appConfigStorage = useStorage<StorageEndpoints>('cluberConfig', {
  endpoints: apiURL,
  recentUsedEndpoints: {},
}, localStorage, { mergeDefaults: true })

export const reactiveApiUrls = reactive(appConfigStorage.value?.endpoints || apiURL)
export const reactiveApiRecentUrls = reactive(appConfigStorage.value?.recentUsedEndpoints)

/**
 * Saves in local storage a list of entries of recently used endpoints to save time
 * @param endpoint - The endpoint name cames from `@core/config/index.ts --> apiURL` and sets that url
 * @param newValue - The endpoint url string
 */
export function setRecentUsedEndpoints(endpoint: KeyOfTypeApiUrl, newValue: string) {
  const currentEndpoint = appConfigStorage.value.recentUsedEndpoints?.[endpoint]

  if (currentEndpoint) {
    if (!currentEndpoint.includes(newValue)) {
      appConfigStorage.value.recentUsedEndpoints[endpoint].push(newValue)
    }
  } else {
    if (!appConfigStorage.value.recentUsedEndpoints) Object.assign(appConfigStorage.value, { recentUsedEndpoints: {} })
    Object.assign(appConfigStorage.value.recentUsedEndpoints, {
      [endpoint]: [newValue],
    })
  }

  Object.assign(reactiveApiRecentUrls, appConfigStorage.value.recentUsedEndpoints)
}

/**
 * In staging and development environments allows us to do hot replacement endpoints
 * @param endpoint - The endpoint name cames from `@core/config/index.ts --> apiURL` and sets that url
 * @param newValue - The endpoint url string
 */
export function setCustomEndpoints(endpoint: KeyOfTypeApiUrl, newValue?: string) {
  if (isProduction) reactiveApiUrls[endpoint] = apiURL[endpoint]

  if (newValue) {
    reactiveApiUrls[endpoint] = newValue

    if (!appConfigStorage.value.endpoints) Object.assign(appConfigStorage.value, { endpoints: {} })

    if (!appConfigStorage.value.endpoints?.[endpoint]) {
      Object.assign(appConfigStorage.value.endpoints, { [endpoint]: newValue })
    } else {
      appConfigStorage.value.endpoints[endpoint] = newValue
    }
  }
}

/**
 * Reset endpoints to defaults set in CONFIG
 */
export function resetEndpoints() {
  Object.assign(reactiveApiUrls, apiURL)
  appConfigStorage.value.endpoints = apiURL
}

export function stringifyRouteQuery(query: unknown) {
  return qs.stringify(query, { encode: false, arrayFormat: 'brackets' })
}

export function parseRouteQuery(query: string) {
  return qs.parse(query)
}

/**
 * Transform a simple string into an url with params and queries
 * @param url - string with the endpoint url
 * @param destructured `{ params, query }` - object containing needed data for URL
 * @param endpoint - The endpoint name cames from `@core/config/index.ts --> apiURL` and sets that url
 */
export function getApiRoute<R>(
  url: string,
  { params, query }: AllowedRequestConfigProperties<R | { params: unknown, query: unknown }> = {},
  endpoint: KeyOfTypeApiUrl = 'main',
) {
  // @ts-expect-error dont know how to solve this
  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-return
  const path = compile(url)(params, { encode: (compiled: unknown) => compiled })
  let routeQuery = stringifyRouteQuery(query)
  routeQuery = routeQuery ? `?${routeQuery}` : ''
  return encodeURI(`${reactiveApiUrls[endpoint]}${path}${routeQuery}`)
}
