import { http, HttpResponse } from 'msw'
import { getLegacyPeopleGroupByClub } from '@dashboard/modules/ProfileSelector/api'
import { getApiRoute } from '@core/utils/api'

import { groupByClubLegacy } from './mockData'

type UrlParams = { params: { clubHash: string } }

export const getGroupByClubLegacy = http.get(
  getApiRoute<UrlParams>(getLegacyPeopleGroupByClub.url, { params: { clubHash: '*' } }, 'legacy'),
  () => HttpResponse.json([groupByClubLegacy]),
)
