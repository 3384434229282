import { http, HttpResponse } from 'msw'
import {
  getClubCommerceCampaign,
  getClubCommerceCampaigns,
  getCampaignSoldProducts,
  getCampaignOrders,
} from '@dashboard/modules/Ecommerce/api'
import { getApiRoute } from '@core/utils/api'

import { campaignOrders, soldProducts } from './mockData'

type UrlParams = { params: { clubHash: string } }

export const getCampaignOrdersHandler = http.get(
  getApiRoute<UrlParams>(getCampaignOrders.url, { params: { clubHash: '*' } }),
  ({ request, params, cookies }) => HttpResponse.json({
    'hydra:member': campaignOrders,
    'hydra:totalItems': 1,
    'hydra:view': {
      '@id': '12345',
      type: 'string',
      'hydra:first': '1',
      'hydra:last': '2',
      'hydra:previous': '1',
      'hydra:next': '2',
    },
    'hydra:search': {
      '@type': 'string',
      'hydra:template': 'string',
      'hydra:variableRepresentation': 'string',
      'hydra:mapping': [
        {
          '@type': 'string',
          variable: 'string',
          property: 'string',
          required: true,
        },
      ],
    },
  }),
)

const getCampaignSoldProductsUrlConfig = getApiRoute<UrlParams>(getCampaignSoldProducts.url, {
  params: {
    clubHash: '*',
    campaignHash: '*',
  },
})

export const getCampaignSoldProductsHandler = http.get(
  getCampaignSoldProductsUrlConfig,
  ({ request, params, cookies }) => HttpResponse.json({
    'hydra:member': soldProducts,
    'hydra:totalItems': 1,
    'hydra:view': {
      '@id': '12345',
      type: 'string',
      'hydra:first': '1',
      'hydra:last': '2',
      'hydra:previous': '1',
      'hydra:next': '2',
    },
    'hydra:search': {
      '@type': 'string',
      'hydra:template': 'string',
      'hydra:variableRepresentation': 'string',
      'hydra:mapping': [
        {
          '@type': 'string',
          variable: 'string',
          property: 'string',
          required: true,
        },
      ],
    },
  }),
)

export const getEcomClubCampaignsHandler = http.get(
  getApiRoute<UrlParams>(getClubCommerceCampaigns.url, { params: { clubHash: '*' } }),
  ({ request, params, cookies }) => HttpResponse.json({
    '@context': '/api/contexts/Campaign',
    '@id': '/api/clubs/club1/commerce/campaigns',
    '@type': 'hydra:Collection',
    'hydra:totalItems': 3,
    'hydra:member': [
      {
        '@id': '/api/clubs/club1/commerce/campaigns/camp1',
        '@type': 'Campaign',
        hash: 'r9c124913a9719b646a0b8aab48bcac2d477fb2c',
        name: 'Property Line 1',
        startingDate: '2023-01-16T16:29:47+00:00',
        endingDate: '2023-01-16T16:29:47+00:00',
        club: '/api/clubs/club1',
        description: 'string',
        createdAt: '2023-01-16T16:34:28+00:00',
        updatedAt: '2023-01-16T16:34:28+00:00',
        image: 'https://loremflickr.com/640/400/sport',
        categories: [
          'demo',
          'evento',
        ],
        collected: 2,
        purchased: {
          total: 14,
          sold: 1,
        },
        published: true,
      },
      {
        '@id': '/api/clubs/club1/commerce/campaigns/a9c114913a9719b646a0b8aab48bcac2d477fb2c',
        '@type': 'Campaign',
        hash: 'a9c114913a9719b646a0b8aab48bcac2d477fb2c',
        name: 'Property Line 2',
        startingDate: '2023-01-16T16:29:47+00:00',
        endingDate: '2023-01-16T16:29:47+00:00',
        club: '/api/clubs/club1',
        description: 'string',
        createdAt: '2023-01-16T16:37:23+00:00',
        updatedAt: '2023-01-16T16:37:23+00:00',
        image: 'https://loremflickr.com/640/400/tennis',
        categories: [
          'demo',
          'event',
          'championship',
          'party',
          'tournament',
          'expo',
        ],
        collected: 2,
        purchased: {
          total: 14,
          sold: 4,
        },
        published: false,
      },
      {
        '@id': '/api/clubs/club1/commerce/campaigns/b8b8f7a63502992d5eaaf0ffb96ae9b70a431980',
        '@type': 'Campaign',
        hash: 'b8b8f7a63502992d5eaaf0ffb96ae9b70a431980',
        name: 'Property Line 3',
        startingDate: '2023-01-16T16:29:47+00:00',
        endingDate: '2023-01-16T16:29:47+00:00',
        club: '/api/clubs/club1',
        description: 'string',
        createdAt: '2023-01-16T16:39:44+00:00',
        updatedAt: '2023-01-16T16:39:44+00:00',
        image: 'https://loremflickr.com/400/900/iceskating',
        categories: [
          'torneo',
        ],
        collected: 2,
        purchased: {
          total: 14,
          sold: 2,
        },
        published: false,
      },
    ],
  }),
)

export const getEcommerceClubCampaignHandler = http.get(
  getApiRoute<UrlParams>(getClubCommerceCampaign.url, { params: { clubHash: '*', campaignHash: '*' } }),
  ({ request, params, cookies }) => HttpResponse.json({
    '@context': '/api/contexts/Campaign',
    '@id': '/api/clubs/club1/commerce/campaigns',
    '@type': 'hydra:Collection',
    hash: '12345',
    name: 'Campaign name',
    startingDate: '2024-01-03T16:38:03.382Z',
    endingDate: '2024-01-03T16:38:03.382Z',
    club: 'string',
    description: 'string',
    createdAt: '2024-01-03T16:38:03.382Z',
    updatedAt: '2024-01-03T16:38:03.382Z',
  }),
)
