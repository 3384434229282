import { EndpointConfig } from '@core/store/apiRequest/declarations'

export const getClubPublicPortalComponents: EndpointConfig = {
  storeKey: 'getClubPublicPortalComponents',
  url: '/api/clubs/:clubHash/portal/components',
}

export const putPublicPortalComponent: EndpointConfig = {
  storeKey: 'putPublicPortalComponent',
  url: '/api/clubs/:clubHash/portal/components/:componentId',
}

export const postPublicPortalComponent: EndpointConfig = {
  storeKey: 'postPublicPortalComponent',
  url: '/api/clubs/:clubHash/portal/components',
}

export const deletePublicPortalComponent: EndpointConfig = {
  storeKey: 'deletePublicPortalComponent',
  url: '/api/clubs/:clubHash/portal/components/:componentId',
}
