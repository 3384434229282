<script setup lang="ts">
import { useI18n } from 'vue-i18n'

import ZohoFormContainer from '@dashboard/components/ZohoFormContainer/ZohoFormContainer.vue'

const { t } = useI18n()

</script>

<template>
  <div>
    <h3 class="font-display font-semibold text-3xl mb-8">
      {{ t('Cluber Help') }}
    </h3>
    <ZohoFormContainer
      class="h-[56rem]"
      form-route="cluber-help"
    />
  </div>
</template>
