import {
  ref,
  watch,
  onBeforeMount,
  type Ref,
} from 'vue'
import { RouteLocationNormalizedLoaded, useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'

export function useBrowserTabTitle(route: RouteLocationNormalizedLoaded) {
  const router = useRouter()
  const { t } = useI18n()
  const documentDefined = typeof document !== 'undefined'
  const originalTitle: Ref<string> = ref(documentDefined ? document.title : '')

  function setTitle() {
    if (!route.meta.tabTitle && (router.currentRoute.value.fullPath === route.fullPath) && (route.fullPath !== '/')) {
      // eslint-disable-next-line no-console
      console.warn('No tab title set for this route. If this is unintentional please set one on route.meta.tabTitle property', route.fullPath, route.meta)
      return
    }
    if (documentDefined && document.title !== route.meta.tabTitle) {
      const title: string | number = route.meta.tabTitle as string | number
      document.title = `Cluber - ${typeof title !== 'string' ? title : t(title)}`
    } else {
      document.title = originalTitle.value
    }
  }

  watch(route, () => setTitle())

  onBeforeMount(() => {
    setTitle()
  })
}
