import {
  type ApiHydraResponse,
} from '@core/store/apiRequest/declarations'

// ***
// REQUESTS
// ***

export interface PatchUserChangePasswordRequest {
  data: {
    currentPassword: string,
    newPassword: string,
  },
}

// ***
// SHAPES
// ***

export interface ApiUserChangePassword extends ApiHydraResponse {
  password: string,
}

export interface UserStore {
  patchUserChangePassword: ApiUserChangePassword,
}

// ***
// MESSAGES
// ***

export enum PatchUserChangePasswordErrorMessages {
  BadCurrentPassword = 'Password is wrong',
}
