import { EndpointConfig } from '@core/store/apiRequest/declarations'

export const getClubCommerceCampaigns: EndpointConfig = {
  storeKey: 'getClubCommerceCampaigns',
  url: '/api/clubs/:clubHash/commerce/campaigns',
}

export const getClubCommerceCampaign: EndpointConfig = {
  storeKey: 'getClubCommerceCampaigns',
  url: '/api/clubs/:clubHash/commerce/campaigns/:campaignHash',
}

export const getCampaignSoldProducts: EndpointConfig = {
  storeKey: 'getCampaignSoldProducts',
  url: '/api/clubs/:clubHash/commerce/campaigns/:campaignHash/products',
}

export const getCampaignOrders: EndpointConfig = {
  storeKey: 'getCampaignOrders',
  url: '/api/clubs/:clubHash/commerce/orders',
}
