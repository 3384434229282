import { merge } from 'lodash-es'

import enUS from './en-US.json'
import enUSBrowserTabs from './browserTab/en-US.json'
import esES from './es-ES.json'
import esESBrowserTabs from './browserTab/es-ES.json'
import euES from './eu-ES.json'
import euESBrowserTabs from './browserTab/eu-ES.json'
import glES from './gl-ES.json'
import glESBrowserTabs from './browserTab/gl-ES.json'
import caES from './ca-ES.json'
import caESBrowserTabs from './browserTab/ca-ES.json'

const enUSLocale = merge(enUS, {
  ...enUSBrowserTabs,
})

const esESLocale = merge(esES, {
  ...esESBrowserTabs,
})

const glESLocale = merge(glES, {
  ...glESBrowserTabs,
})

const euESLocale = merge(euES, {
  ...euESBrowserTabs,
})

const caESLocale = merge(caES, {
  ...caESBrowserTabs,
})

const i18nLocales = {
  'ca-ES': caESLocale,
  'en-US': enUSLocale,
  'es-ES': esESLocale,
  'gl-ES': glESLocale,
  'eu-ES': euESLocale,
}

export default i18nLocales
