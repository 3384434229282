import { type Subsidy } from '@libs/fsc/types/legacy'

export interface UserDataLegacy {
  clubName?: string,
  clubLogo?: string,
  name?: string,
  lastname?: string,
  subsidies?: Subsidy[],
  email?: string | null,
  phone?: string,
  id?: string,
}

export enum RoleKind {
  Member = 'ROLE_MEMBER',
  Guardian = 'ROLE_GUARDIAN',
  Manager = 'ROLE_MANAGER',
  Player = 'ROLE_PLAYER',
  External = 'ROLE_EXTERNAL',
}

export type AllowedRoleKind = `${RoleKind}`

export type ExcludeExternalRole = Exclude<AllowedRoleKind, 'ROLE_EXTERNAL'>

export enum UserKind {
  Member = 'member',
  Guardian = 'guardian',
  External = 'external',
  Manager = 'manager',
  Player = 'player',
}

export type AllowedUserKind = `${UserKind}`

export type ExcludeExternalKind = Exclude<AllowedUserKind, 'external'>
