import { ModuleRoutes } from '@core/declarations/router'
import { RoleKind } from '@libs/fsc/types/user'

const CampaignsList = () => import('@dashboard/modules/Ecommerce/views/CampaignsList/CampaignsList.vue')
const CampaignSummary = () => import('@dashboard/modules/Ecommerce/views/CampaignSummary/CampaignSummary.vue')

const routes: ModuleRoutes = {
  name: 'Ecommerce',
  basePath: '/dashboard/manager/ecommerce/:clubHash',
  roleKind: RoleKind.Manager,
  routes: [
    {
      path: '/campaigns/list',
      name: 'dashboardCampaigns',
      component: CampaignsList,
      meta: {
        tabTitle: 'Ecommerce: campaigns list',
      },
    },
    {
      path: '/campaign/:campaignHash',
      name: 'dashboardCampaignSummary',
      component: CampaignSummary,
      meta: {
        tabTitle: 'Ecommerce: campaign summary',
      },
    },
  ],
}

export default routes
