<script setup lang="ts">
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute, useRouter } from 'vue-router'

import { CbButton } from '@cluber/carabiner'

// ***
// COMMON
// ***
const { t } = useI18n()
const route = useRoute()
const router = useRouter()

const homeButton = {
  title: 'Go homepage',
  action: () => router.push({ name: 'profileSelector' }),
}
const loginButton = {
  title: 'Log In',
  action: () => {},
}

interface StatusViewProperties {
  title: string,
  description: string,
  image: string,
  buttons: Array<{
    title: string,
    action: () => void,
  }>
}

// ***
// VIEWS CONFIG
// ***

const commonView: StatusViewProperties = {
  title: 'You found our <span class="text-[3rem] text-primary-500"> { errorCode } </span> page',
  description: 'Something wrong happened. Try refreshing or use the following button to return homepage',
  image: 'https://svgshare.com/i/v1i.svg',
  buttons: [
    homeButton,
  ],
}

const status: Record<string, StatusViewProperties> = {
  401: {
    title: 'You found our <span class="text-[3rem] text-primary-500"> { errorCode } </span> page',
    description: 'Sadly you are not authorized to visit this page. Maybe you could gain access login or signin in with the following button',
    image: 'https://svgshare.com/i/v1t.svg',
    buttons: [
      loginButton,
      homeButton,
    ],
  },
  403: {
    title: 'You found our <span class="text-[3rem] text-primary-500"> { errorCode } </span> page',
    description: 'But unafortunately with your current user you are unauthorized to see this page. Ask for permissions or return to homepage',
    image: 'https://svgshare.com/i/v2Q.svg',
    buttons: [
      homeButton,
    ],
  },
  404: {
    title: 'You found our <span class="text-[3rem] text-primary-500"> { errorCode } </span> page',
    description: "But unafortunately we didn't find the page you are looking for. Check the URL or use the following button to return homepage",
    image: 'https://svgshare.com/i/v1i.svg',
    buttons: [
      homeButton,
    ],
  },
  500: {
    title: 'You found our <span class="text-[3rem] text-primary-500"> { errorCode } </span> page',
    description: 'This means we had some problem with our server. Try refreshing this page. If this issue persist for long contact us. Meanwhile you could go back to homepage',
    image: 'https://svgshare.com/i/v10.svg',
    buttons: [
      homeButton,
    ],
  },
  502: {
    title: 'You found our <span class="text-[3rem] text-primary-500"> { errorCode } </span> page',
    description: "This means there are some connectivity issues. Try checking your connection. If it's on our end wait a bit. Meanwhile you could go back to homepage",
    image: 'https://svgshare.com/i/v10.svg',
    buttons: [
      homeButton,
    ],
  },
  504: {
    title: 'You found our <span class="text-[3rem] text-primary-500"> { errorCode } </span> page',
    description: 'This means the server did not respond in the allowed time period. Wait a bit and refresh, or go homepage if error persist',
    image: 'https://svgshare.com/i/v10.svg',
    buttons: [
      homeButton,
    ],
  },
}

const getStatusProperties = computed<StatusViewProperties>(() => {
  if (status[route.meta.errorCode as keyof typeof status]) {
    return status[route.meta.errorCode as keyof typeof status]
  }
  return commonView
})
</script>

<template>
  <div class="w-screen h-screen flex md:items-center flex-col">
    <div class="w-full h-full text-center md:text-left md:w-10/12 md:max-w-[64rem] md:min-h-[38rem] md:h-3/6 rounded md:shadow md:mx-auto md:flex bg-white md:mt-auto dark:bg-black">
      <div class="flex flex-col flex-1 my-auto md:flex-[1_0_35%] lg:flex-[1_0_55%] md:max-w-[500px] md:pl-12">
        <img
          :src="getStatusProperties.image"
          alt=""
          class="h-[18rem] md:h-auto"
        />
        <p class="text-center text-[.4rem]">
          <a href="https://www.freepik.com/free-vector/funny-monster-hand-drawn-doodle-vector_9947876.htm#&position=26&from_view=author">
            Image by gohsantosa2
          </a> on Freepik
        </p>
      </div>
      <div class="p-12 md:mt-12">
        <h1 class="font-bold text-[4rem]">
          Ooops!
        </h1>
        <h2
          class="text-3xl font-bold"
          v-html="t(getStatusProperties.title, { errorCode: route.meta.errorCode })"
        />
        <p class="text-2xl">
          {{ t(getStatusProperties.description) }}
        </p>
        <div class="space-y-4 space-x-4 mt-12">
          <CbButton
            v-for="button in getStatusProperties.buttons"
            :key="button.title"
            mode="filled"
            color="primary"
            class="mx-auto"
            size="lg"
            @click="button.action"
          >
            {{ t(button.title) }}
          </CbButton>
        </div>
      </div>
    </div>
    <footer class="flex-col md:flex-row md:mt-auto w-full flex p-5 bg-white dark:bg-base-1000 border-t border-base-200 z-10">
      <div class="flex md:mr-auto items-center mx-auto md:mx-0 mb-5 md:mb-auto">
        <img
          src="https://app.cluber.es/build/images/logo-footer.382f278b.svg"
          alt="Cluber logo"
        />
        <span class="hidden md:block">
          Impulsamos clubes deportivos (NT)
        </span>
      </div>
      <div class="footer-items flex flex-column flex-wrap items-center md:space-x-4 [&>*]:w-full md:[&>*]:w-auto [&>*]:text-center [&>*]:cursor-pointer">
        <a
          href="/es/politica-privacidad/legal"
        >
          Aviso Legal (NT)
        </a>
        <a
          href="/es/politica-privacidad/privacy"
        >
          Política de Privacidad (NT)
        </a>
        <a>
          Política de Cookies (NT)
        </a>
        <a
          href="/es/politica-privacidad/clubs"
        >
          Condiciones Generales (NT)
        </a>
      </div>
    </footer>
  </div>
</template>
