import { ModuleRoutes } from '@core/declarations/router'
import { RoleKind } from '@libs/fsc/types/user'

import { manager } from '@dashboard/modules/Messages/routes'
import { managerBooking } from '@dashboard/modules/Bookings/routes'

const ManagerOverview = () => import('@dashboard/modules/Manager/views/Overview/ManagerOverview.vue')

const routes: ModuleRoutes = {
  name: 'Manager',
  basePath: '/dashboard/manager',
  roleKind: RoleKind.Manager,
  routes: [
    ...manager,
    ...managerBooking,
    {
      path: '/:clubHash/overview',
      name: 'managerOverview',
      component: ManagerOverview,
      meta: {
        tabTitle: 'Manager overview',
      },
    },
  ],
}

export default routes
