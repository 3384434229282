import { http, HttpResponse } from 'msw'

import { getClubPublicPortalComponents, postPublicPortalComponent } from '@portal/modules/PublicWebsite/api'
import { getApiRoute } from '@core/utils/api'

import { componentsList } from './mockData'

type UrlParams = { params: { clubHash: string } }

export const getComponentsList = http.get(
  getApiRoute<UrlParams>(getClubPublicPortalComponents.url, { params: { clubHash: '*' } }, 'main'),
  ({ request, params, cookies }) => HttpResponse.json({
    '@context': '/api/contexts/PortalComponent',
    '@id': '/api/clubs/3abf15a877a6dabfc67806017ab195526a3b011d/portal/components',
    '@type': 'hydra:Collection',
    'hydra:totalItems': 1,
    'hydra:member': componentsList,
    'hydra:view': {
      '@id': '/api/clubs/3abf15a877a6dabfc67806017ab195526a3b011d/portal/components?size=10',
      '@type': 'hydra:PartialCollectionView',
    },
  }),
)

export const postComponent = http.post(
  getApiRoute<UrlParams>(postPublicPortalComponent.url, { params: { clubHash: '*' } }, 'main'),
  ({ request, params, cookies }) => HttpResponse.json({}),
)
